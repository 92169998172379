.purple {
	color: #8b2ad5;
}

.green {
	color: #07DCAC !important;
}

.blue {
	color: #0090FF !important;
}

.orange {
	color: #FF6F00 !important;
}

.yellow {
	color: #D69608 !important;
}

.red {
	color: #ff4f65 !important;
}

.white {
	color: #ffffff;
}

.purple-bg {
	background-color: #7D1DD1;
}

.orange-bg {
	background-color: #F5A623;
}

.green-bg {
	background-color: #00DDAB;
}

.red-bg {
	background-color: #FF4F65;
}

.white-bg {
	background-color: #FFFFFF !important;
}

.transparent-gray-bg {
	background-color: rgba(115,118,137,0.92);
}