.Toastify:not(:last-child) {
	margin-bottom: 20px;
}

.Toastify__toast-body {
    margin-right: 20px;
}

.Toastify__toast {
    border-radius: 12px;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
    font-family: Avenir-Medium;
}

.Toastify__close-button {
    font-size: 18px;
    align-self: center
}

.Toastify__toast--error {
    background: rgba(255, 79, 101, 1);
}

.Toastify__toast--success {
    background: rgba(14, 203, 160, 1);
}

.Toastify__toast--info {
    background: rgba(255, 111, 0, 1);
}
