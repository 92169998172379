/* Fonts extracted from Avenir.ttc to make it easier to use */

@font-face {
  font-family: Avenir-Black;
  src: url(//s3-sa-east-1.amazonaws.com/eyemobile-cdn/fonts/Avenir-Black.ttf);
}

@font-face {
  font-family: Avenir-Heavy;
  src: url(//s3-sa-east-1.amazonaws.com/eyemobile-cdn/fonts/Avenir-Heavy.ttf);
}

@font-face {
  font-family: Avenir-Medium;
  src: url(//s3-sa-east-1.amazonaws.com/eyemobile-cdn/fonts/Avenir-Medium.ttf);
}

@font-face {
  font-family: Avenir-Roman;
  src: url(//s3-sa-east-1.amazonaws.com/eyemobile-cdn/fonts/Avenir-Roman.ttf);
}

@font-face {
  font-family: DIN-Condensed-Bold;
  src: url(//s3-sa-east-1.amazonaws.com/eyemobile-cdn/fonts/DINCondensedBold.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

body {
  margin: 0;
  background: #F5F7FA;
  font-family: Avenir-Medium;
}

html, body {
  height: 100%;
}

#root {
  height: 100%;
}

@media print {
  div {
    display: block !important;
    box-sizing: content-box !important;
    overflow: hidden !important;
  }

  body{
    overflow: hidden !important;
  }
}

.clearfix:before, .clearfix:after{
  display: block !important;
}

button, input, optgroup, select, textarea {
  font-family: Avenir-Medium;
}

h1 {
  font-size: 70px;
  margin: 0;
  font-weight: normal;
}

.fw {
  width: 100% !important;
}

.fh {
  height: 100% !important;
}

.f-vh {
  height: 100vh;
}

.vh-80 {
  height: 80vh;
}

.content-body {
  height: 100%;
  display: flex;
}

.page-wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page-content-wrapper {
  min-height: calc(100vh - 118px);
  display: flex;
  flex-direction: column;
}

.content-header, .main, .footer {
  margin-left: 112px;
}

.content-header {
  padding-right: 80px;
}

.main {
  margin-top: 50px;
  height: calc(100% - 50px);
  margin-left: 112px;
  padding: 40px;
}

.dashboard {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.buttons {
  display: flex;
  align-items: center;
}

.footer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
}

.gray-pill,
.white-pill,
.red-pill,
.purple-pill,
.light-blue-pill {
  font-size: 13px;
  padding: 3px 20px;
  border-radius: 20px;
  align-items: center;
  font-family: Avenir-Medium;
  text-transform: uppercase;
}

.gray-pill {
  color: #fff;
  background-color: #727588;
}

.white-pill {
  color: #7d1dd1 !important;
  background-color: #FFFFFF;
}

a.white-pill:hover {
  color: #7d1dd1 !important;
}

.red-pill {
  color: #fff;
  background-color: #FF4F65;
}

.purple-pill {
  color: #fff;
  background-color: #7d1dd1;
}

.light-blue-pill {
  color: #737689;
  background-color: #B9D6E6;
  text-transform: none;
}

.hide {
  display: none !important;
  margin: 0;
}

.mobile {
  display: none;
}

.eye {
  color: white;
  background: url(../assets/ic_eye.svg) no-repeat center;
}

.m-back-layout {
  margin-bottom: 20px;
  margin-right: 20px;
  padding: 1px 20px 0 !important;
  height: 23px;
}

:focus {
  outline: none !important;
}

.no-border {
  border: none !important;
}

.bs-popover-auto[x-placement^=right], .bs-popover-right,
.bs-popover-auto[x-placement^=left], .bs-popover-left,
.bs-popover-auto[x-placement^=top], .bs-popover-top,
.bs-popover-auto[x-placement^=bottom], .bs-popover-bottom {
  background-color: rgb(115, 118, 137);
  color: white;
  border-radius: 10px;
  padding: 15px;
  font-size: 16px;
}

.bs-popover-auto[x-placement^=right] .arrow::after, .bs-popover-right .arrow::after {
  border-right-color: rgb(115, 118, 137) !important;
}

.bs-popover-auto[x-placement^=left] .arrow::after, .bs-popover-left .arrow::after {
  border-left-color: rgb(115, 118, 137) !important;
}

.bs-popover-auto[x-placement^=bottom] .arrow::after, .bs-popover-bottom .arrow::after {
  border-bottom-color: rgb(115, 118, 137) !important;
}

.bs-popover-auto[x-placement^=top] .arrow::after, .bs-popover-top .arrow::after {
  border-top-color: rgb(115, 118, 137) !important;
}

.popover {
  z-index: 1060;
  font-family: Avenir-Medium !important;
}

.pagination {
  width: 100%;
}

.pagination > li > a, .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #7d1dd1;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  z-index: 3;
  color: #fff !important;
  background-color: #7d1dd1;
  border-color: #7d1dd1;
  cursor: default;
}

.pagination > li > a:focus, .pagination > li > a:hover, .pagination > li > span:focus, .pagination > li > span:hover {
  z-index: 2;
  color: #7d1dd1 !important;
  background-color: #eee;
  border-color: #ddd;
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  z-index: 3;
  color: #fff !important;
  background-color: #7d1dd1;
  border-color: #7d1dd1;
  cursor: default;
}

.horizontal-form {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.horizontal-form label {
  margin-right: 1em;
  font-size: 12px;
  font-family: Avenir-Roman;
  color: #737689;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 0px;
}

.horizontal-form input {
  border-radius: 17px !important;
  padding: 6px;
  border: 1px solid #BABDC5;
  text-transform: uppercase;
  color: #737689;
  font-size: 12px;
  text-align: center;
}

.horizontal-form input:focus, .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #7d1dd1;
  outline: 0;
  box-shadow: none;
}

.box-content {
  display: flex;
  flex-wrap: wrap-reverse;
}

@media (min-width: 768px) {
  .box-content {
    padding-top: 0 !important;
  }
}

.react-datepicker__header {
  background-color: #7d1dd1 !important;
}

.react-datepicker__header > .react-datepicker__day-names > .react-datepicker__day-name, .react-datepicker__header > .react-datepicker__current-month {
  color: white !important;
}

.center-content {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 18px;
  flex-direction: column;
  text-transform: none !important;
  flex-grow: 1;
}

.center-content > span {
  margin-top: 1em;
}

.no-scroll {
  overflow: initial !important;
  white-space: initial !important;
}

.scroll{
  overflow: auto;
}

.recharts-tooltip-item-list, .recharts-tooltip-label {
  font-size: 14px !important;
  text-transform: uppercase !important;
}

.title {
  font-family: Avenir-Roman;
  color: #737689;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

@media (min-width: 768px) {
  .title {
    margin-top: 0;
  }
}

.header-info {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid #d9dbe0;
  margin-bottom: 20px;
}

.sub-title {
  font-family: Avenir-Medium;
  color: #09D0A3;
  font-size: 35px;
  display: flex;
}

.ic-view-dashboard {
  margin-left: 8px;
  margin-bottom: 4px;
  cursor: pointer;
}

.bar-scroll {
  overflow: auto !important;
  height: 100vh !important;
}

.line {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  color: #737689;
  overflow: hidden;
  text-overflow: ellipsis;
}

.primary-color {
  color: #7d1dd1;
}

.secondary-color {
  color: #07dcac;
}

.tertiary-color {
  color: #6a90c9;
}

.red-color {
  color: #d6546c;
}

.blur {
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -ms-filter: blur(8px);
  -o-filter: blur(8px);
  filter: blur(8px);
}

.max-80 {
  max-width: 80%;
}

.max-70 {
  max-width: 70%;
}

.max-65 {
  max-width: 65%;
}

.w-55 {
  width: 55% !important;
}

.w-50 {
  width: 50%;
  max-width: 50%;
}

.max-50 {
  max-width: 50%;
}

.w-58 {
  width: 58% !important;
}

.w-60 {
  width: 60% !important;
}

.e-w-75 {
  width: 75%;
}

.react-switch-bg {
  background-color: #d5d5d5;
}

.bold {
  font-weight: bold;
}

.link {
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.back-to-top {
  position: fixed;
  right: 18px;
  bottom: 50px;
  z-index: 98;
  cursor: pointer;
  width: 38px;
}

.btn-purple.focus, .btn-purple:focus {
  outline: 0;
  box-shadow: none !important;
}

.no-data > img {
  max-width: 350px;
}

.no-data {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #737689;
  font-size: 18px;
  flex-direction: column;
  flex-grow: 1;
  text-transform: none;
}

a {
  color: inherit;
  &:hover {
    color: inherit;
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(90deg, #ffffff 8%, #dedede 18%, #fff 33%);
  background-size: 800px 104px;
  position: relative;
}

.label-height {
  height: 20px;
  width: 100%;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
}

.pointer {
  cursor: pointer !important;
}

i {
  display: block;
  flex-shrink: 0;
}

.generic-card {
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  border-radius: 12px;
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 20px;
  -webkit-box-shadow: 0 2px 5px 0 #73768944;
  -moz-box-shadow: 0 2px 5px 0 #73768944;
  box-shadow: 0 2px 5px 0 #73768944;
}

.cards-wrapper {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  padding-bottom: 20px;
  margin-right: -15px;
  margin-left: -15px;
}

.no-break {
  white-space: nowrap;
}

.break {
  white-space: pre-line;
}

.break-center {
  white-space: pre-line;
  text-align: center;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 800ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 800ms ease-in;
}

.ov-hidden {
  overflow: hidden;
}

.ov-visible {
  overflow: visible;
}

.ov-y-auto {
  overflow-y: auto;
}

.ov-y-visible {
  overflow-y: visible !important;
}

.relative {
  position: relative;
}

.w-50{
  width: 50% !important;
}

.w-70{
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-150 {
  width: 150% !important;
}

.container-floatButton .input-icon {
  right: 88px;
}

.container-select .input-icon {
  right: 55px;
  top: 7px;
}

.page-break {
  page-break-after: always;
  float: none !important;
}

.bg-gray {
  background-color: gray;
}

.color-default{
  color: #737689;
}

.ic-label {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.bb-white {
  border-bottom: 1px solid #fff;
}

.round {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.round-sm {
  width: 12px;
  height: 12px;
}

.box-border-gray {
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  padding: 12px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
}

.month-filter {
  width: 230px;
}

.Select-menu-outer {
  z-index: 99999;
}

.Select-option {
  font-size: 14px;
}

.select-option-image {
  width: 40px;
  height: 40px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
}
.info-length {
  position: absolute;
  right: 4px;
  bottom: -20px;
}

.text-wrap {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.img-sm {
  width: 40px !important;
  height: 40px !important;
}

.white {
  color: white !important;
}

.new-recipient-security-list {
  max-height: 510px;
  overflow-y: auto;
}

.eye-card.md {
  width: 550px;
}

.point-form-image {
  border-radius: 35px;
}

#settlementInfo.open #settlementInfoFormWrapper {
  height: 660px;
}

#settlementInfo.closed #settlementInfoFormWrapper {
  height: 0;
  visibility: hidden;
  overflow: hidden;
}

.disable-clicks {
  pointer-events: none;
  user-select: none;
}

.point-type-select {
  max-width: 263px;
}

.point-type-select .react-select__placeholder {
  text-transform: uppercase;
}

.point-eye-card-p {
  padding: 15px 25px;
}

.size-30 {
  width: 30px !important;
  height: 30px !important;
}

.point-participation-card {
  width: 230px;
  height: 265px;
}

@media(width: 1366px) {
  .point-participation-card {
    width: 218px;
  }
}

.point-user-card {
  width: 250px;
  height: 230px;
}

#addParticipationModal .internal-area {
  width: 560px;
}

.card-tag {
  position: absolute;
  left: 0;
  top: 0;
}

.disabled {
  opacity: 0.6;
  user-select: none;
  pointer-events: none;
}

.blue-rounded-box {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  padding: 15px;
  border: 1.5px solid #009BDD;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

#charge-history-title {
  margin: 35px 0 35px 35px !important;
}

i.disabled {
  opacity: 0.5 !important;
  pointer-events: none;
  cursor: default;
}

.profile-page .content {
  padding: 20px 40px;
}

.opaque-hover:hover {
    background-color: #ffffff1a !important;
}

.react-date-picker__calendar {
  z-index: 2;
}

.react-datepicker-popper {
  z-index: 2;
}

.toggle-fade.show {
  opacity: 1;
  pointer-events: initial;
  cursor: initial;
}

.toggle-fade {
  transition: opacity ease-in-out .3s;
  opacity: 0;
  pointer-events: none;
  cursor: none;
}

@media (max-width: 1920px) {
  .statistics-placeholder-height {
    height: 797px;
  }
}

@media (max-width: 1850px) {
  .statistics-placeholder-height {
    height: 647px;
  }

  .mh-80vh-xs {
    max-height: 80vh;
  }
}

@media (max-width: 1366px) {
  .statistics-placeholder-height {
    height: 537px;
  }
}

.max-height-500 {
  max-height: 500px;
}

.mh-50 {
  max-height: 50%;
}

.h-80 {
  height: 80% !important;
}

.break-line-word {
  white-space: normal;
  word-break: break-word;
  -ms-word-break: break-word;
}

.mw-200 {
  max-width: 200px;
}

.mw-230 {
  max-width: 230px;
}

g.recharts-layer.recharts-cartesian-axis-tick text > tspan {
  font-size: 14px;
  color: #a0a1a6;
}

.purple-border {
  border: 2px solid #7d1dd1;
  border-radius: 100px;
}

#mobile-indicator {
  display: none;
}

.nowrap {
  white-space: nowrap;
}

.products-overflow {
  overflow-y: hidden !important;
  overflow-x: auto !important;
}

.timeline-line {
  flex: 1;
  margin-top: 4px;
  margin-bottom: 4px;
  width: 4px;
  background-color: #FFFFFF;
}

.last-update-info {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: transparent;
  color: #727588;
  font-size: 13px;
  text-transform: uppercase;
}

.menu-content .mobile-link {
  display: none;
  margin-top: 14px;
  margin-left: 55px;
}

.mobile-link:hover {
  opacity: 1;
}

.menu-content .mobile-link span {
  margin: 0;
}

.input-loading {
  position: absolute;
  right: 7px;
  top: 20%;
}

@media (max-width: 768px) {
  .eye-card {
    width: 100% !important;
    flex: 0 0 100%;
  }

  .footer {
    justify-content: center !important;
    height: 100px;
  }

  .content-header, .main, .footer {
    margin-left: 0 !important;
    padding-right: 20px;
    padding-left: 20px;
  }

  .main {
    margin-left: 0 !important;
    padding: 20px 20px 0 20px;
  }

  .ad-sub {
    font-size: 25px !important;
  }

  h1 {
    font-size: 40px;
  }

  .last-update-mobile {
    bottom: 0;
    left: 0;
    right: 0;
    height: 39px;
    color: #737689;
    position: fixed;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex !important;
    background-color: rgba(245, 247, 250, 0.8);
  }

  .round-box-sm {
    width: 100%;
  }

  .buttons {
    overflow: auto;
    white-space: nowrap;
  }

  .m-back-layout {
    margin-right: 0 !important;
    margin-bottom: 60px;
  }

  .box-content > .col-md-6:nth-child(1) {
    margin-top: 3em;
  }

  .box-content > .col-md-6 {
    padding-left: 4px !important;
  }

  .center-content > img {
    max-width: 230px;
  }

  .center-content > span {
    font-size: 14px;
    text-align: center;
    padding: 2px;
  }

  #mobile-indicator {
    display: block;
  }

  .btn-md {
    min-width: 125px !important;
  }

  .last-update-info {
    margin-top: .5em;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
  }

  .footer {
    margin-top: 20px;
    justify-content: center !important;
  }

  .avatar {
    margin: 0 !important;
  }

  .mobile {
    display: initial;
  }

  .mobile.flex {
    display: flex;
  }

  .desktop {
    display: none !important;
  }

  .menu-content .mobile-link {
    display: flex;
  }

  .order-1-xs {
    order : 1;
  }

  .order-2-xs {
    order : 2;
  }

  .cards-wrapper {
    justify-content: center;
    margin: 0;
  }

  .w-85-xs {
    width: 85% !important;
  }

  #addParticipationModal .internal-area {
    width: 330px;
  }

  .fh-xs {
    height: 100% !important;
  }

  .fw-xs {
    width: 100% !important;
  }

  .no-data > img {
    max-width: 200px;
  }
}

.popover {
  top: 10px !important;
}

.bs-popover-auto[x-placement^=bottom] > .arrow, .bs-popover-bottom > .arrow {
  top: calc(-.5rem + -1px);
}
.popover .arrow {
  width: 1rem;
  height: .5rem;
  margin: 0 .3rem;
}

.popover, .popover .arrow {
  position: absolute;
  display: block;
}

.bs-popover-auto[x-placement^=bottom] > .arrow:before, .bs-popover-bottom > .arrow:before {
  top: 0;
  border-width: 0 .5rem .5rem;
  border-bottom-color: rgba(0,0,0,.25);
}

.popover .arrow:after, .popover .arrow:before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-auto[x-placement^=bottom] .arrow:after, .bs-popover-bottom .arrow:after {
  border-bottom-color: #737689!important;
}

.bs-popover-auto[x-placement^=bottom] > .arrow:after, .bs-popover-bottom > .arrow:after {
  top: 1px;
  border-width: 0 .5rem .5rem;
  border-bottom-color: #fff;
}

.popover .arrow:after, .popover .arrow:before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.menu-content > a {
  text-decoration: none;
}

.filter-label {
  font-size: 12px;
  font-family: Avenir-Roman;
  color: #737689;
  font-weight: 600;
  white-space: nowrap;
  text-transform: uppercase;
  margin-right: 10px;
}
