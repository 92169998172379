* {
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.normal-case {
  text-transform: initial;
}

.normal {
  font-weight: normal;
}

.bold {
  font-weight: bold;
  letter-spacing: 0.4px;
}

.default-label {
  font-size: 12px;
  font-family: Avenir-Roman;
  color: #737689;
  font-weight: 600;
  white-space: nowrap;
  text-transform: uppercase;
  margin-right: 10px;
  margin-bottom: 0;
}

.avenir-roman {
  font-family: Avenir-Roman;
}

.avenir-roman-md2 {
  font-family: Avenir-Roman;
  font-size: 14.5px;
}

.avenir-roman-white-title-md2 {
  color: #ffffff;
  font-family: Avenir-Roman;
  font-size: 16px;
}

.avenir-medium-gray-sm {
  color: #737689;
  font-family: Avenir-Medium;
  font-size: 13px;
}

.avenir-medium-gray-md {
  color: #737689;
  font-family: Avenir-Medium;
  font-size: 14px;
}

.avenir-medium-white {
  color: #ffffff;
  font-family: Avenir-Medium;
}

.avenir-medium-white-md {
  color: #ffffff;
  font-family: Avenir-Medium;
  font-size: 14px;
}

.avenir-medium-dark-gray-md {
  font-family: Avenir-Medium;
  font-size: 13.5px;
}

.avenir-medium-purple-md {
  color: #8b2ad5;
  font-family: Avenir-Medium;
  font-size: 14px;
}

.avenir-medium-gray-md {
  color: #737689;
  font-family: Avenir-Medium;
  font-size: 14px;
}

.avenir-medium-gray-md2 {
  color: #737689;
  font-family: Avenir-Medium;
  font-size: 15px;
}

.avenir-medium-gray-md3 {
  color: #737689;
  font-family: Avenir-Medium;
  font-size: 16px;
}

.avenir-medium-gray-title {
  color: #737689;
  font-family: Avenir-Medium;
  font-size: 18px;
}

.avenir-medium-white-title {
  color: #737689;
  font-family: Avenir-Medium;
  font-size: 18px;
}

.avenir-medium-white-md2 {
  color: #fff;
  font-family: Avenir-Medium;
  font-size: 16px;
}

.avenir-medium-green-md {
  color: #07dcac;
  font-family: Avenir-Medium;
  font-size: 14px;
}

.avenir-medium-green-md2 {
  color: #07dcac;
  font-family: Avenir-Medium;
  font-size: 16px;
}

.avenir-medium-red-lg {
  color: #FF4F65;
  font-family: Avenir-Medium;
  font-size: 22px;
}

.avenir-medium-red-xl {
  color: #FF4F65;
  font-family: Avenir-Medium;
  font-size: 35px;
}

.avenir-medium-purple-md2 {
  color: #7D1DD1;
  font-family: Avenir-Medium;
  font-size: 20px;
}

.avenir-medium-purple-lg {
  color: #7D1DD1;
  font-family: Avenir-Medium;
  font-size: 22px;
}

.avenir-medium-purple-xl {
  color: #7D1DD1;
  font-family: Avenir-Medium;
  font-size: 35px;
}

.avenir-medium-orange-lg {
  color: #FF6F00;
  font-family: Avenir-Medium;
  font-size: 22px;
}

.avenir-medium-orange-xl {
  color: #FF6F00;
  font-family: Avenir-Medium;
  font-size: 35px;
}

.avenir-medium-blue-lg {
  color: #0090FF;
  font-family: Avenir-Medium;
  font-size: 22px;
}

.avenir-medium-blue-xl {
  color: #0090FF;
  font-family: Avenir-Medium;
  font-size: 35px;
}

.avenir-medium-turquoise-lg {
  color: #2ACFD5;
  font-family: Avenir-Medium;
  font-size: 22px;
}

.avenir-medium-turquoise-xl {
  color: #2ACFD5;
  font-family: Avenir-Medium;
  font-size: 35px;
}

.avenir-medium-white-title {
  color: #ffffff;
  font-family: Avenir-Medium;
  font-size: 18px;
}

.avenir-medium-white-title-md {
  color: #ffffff;
  font-family: Avenir-Medium;
  font-size: 20px;
}

.avenir-medium-white-title-md2 {
  color: #ffffff;
  font-family: Avenir-Medium;
  font-size: 22px;
}

.avenir-medium-white-title-xl {
  color: #ffffff;
  font-family: Avenir-Medium;
  font-size: 25px;
}

.avenir-roman-white-title-xl {
  color: #ffffff;
  font-family: Avenir-Roman;
  font-size: 25px;
}

.avenir-heavy-gray-md {
  color: #737689;
  font-family: Avenir-Heavy;
  font-size: 14px;
}

.avenir-heavy-gray-md2 {
  color: #737689;
  font-family: Avenir-Heavy;
  font-size: 15px;
}

.avenir-heavy-gray-xl {
  color: #737689;
  font-family: Avenir-Heavy;
  font-size: 16px;
}

.avenir-heavy-blue-md2 {
  color: #0090FF;
  font-family: Avenir-Heavy;
  font-size: 15px;
}

.avenir-heavy-dark-gray-title {
  color: #737689;
  font-family: Avenir-Heavy;
  font-size: 18px;
}

.avenir-heavy-white-md {
  color: #ffffff;
  font-family: Avenir-Heavy;
  font-size: 14px;
}

.avenir-heavy-purple-md {
  color: #8b2ad5;
  font-family: Avenir-Heavy;
  font-size: 13.5px;
}

.avenir-heavy-blue-md {
  color: #5496e3;
  font-family: Avenir-Heavy;
  font-size: 13.5px;
}

.avenir-heavy-orange-md {
  color: #ff6f00;
  font-family: Avenir-Heavy;
  font-size: 13.5px;
}

.avenir-heavy-green-md {
  color: #07dcac;
  font-family: Avenir-Heavy;
  font-size: 13.5px;
}

.avenir-black-white-md {
  color: #ffffff;
  font-family: Avenir-Black;
  font-size: 14px;
}

.avenir-black-white-md2 {
  color: #ffffff;
  font-family: Avenir-Black;
  font-size: 16px;
}

.text-transform-none {
  text-transform: none;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.total-currency-label {
  color: #09D0A3;
  font-size: 35px;
  line-height: 1;
}

.font-42 {
  font-size: 42px;
}

.font-xxl {
  font-size: 35px;
}

.font-xl {
  font-size: 24px;
}

.font-md {
  font-size: 20px;
}

.font-m {
  font-size: 18px;
}

.font-normal {
  font-size: 16px;
}

.font-sm {
  font-size: 14px !important;
}

.font-s {
  font-size: 12px;
}
.avenir-heavy {
  font-family: Avenir-Heavy;
}

.avenir-medium{
  font-family: Avenir-Medium !important;
}

.total-currency-label-white {
  font-family: Avenir-Medium;
  color: #fff;
  font-size: 30px;
  line-height: 1;
}

.total-currency {
  color: #09D0A3;
  font-size: 60px;
  line-height: 0.9;
}

.total-currency-dark-gray {
  font-family: Avenir-Roman;
  color: #737689 !important;
  font-size: 42px !important;
  height: auto !important;
}

.total-currency-total-white {
  font-family: Avenir-Medium;
  color: #fff;
  font-size: 45px;
  line-height: 0.9;
}

.line-h1 {
  line-height: 1;
}

@media(max-width: 768px) {
  .font-s-xs {
    font-size: 12px !important;
  }

  .text-left-xs {
    text-align: left !important;
  }
}
