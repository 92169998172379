.btn-view {
	min-width: auto;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #727588;
	color: white;
	opacity: 0.6;
	border-radius: 20px;
	border: none;
	cursor: pointer;
	font-size: 12px !important;
	padding: 6px 22px;
	text-transform: uppercase;
	white-space: nowrap;
	border: 0;
	transition: color .15s ease-in-out,
	opacity .15s ease-in-out,
	background-color .15s ease-in-out;
}

.btn-view:hover {
	color: white;
}

.btn-view.selected {
	opacity: 1;
}

button:disabled {
	opacity: 0.5 !important;
	pointer-events: none !important;
	cursor: default !important;
}

.btn-solid-green:hover,
.btn-solid-blue:hover,
.btn-solid-gray:hover,
.btn-solid-red:hover,
.btn-purple:hover {
	opacity: 1;
}

.btn-solid-gray {
	opacity: 1;
	color: #fff;
	background-color: #727588;
}

.btn-red {
	opacity: 1;
	color: #FF4F65;
	border: 1px solid #FF4F65;
	background-color: transparent;
}

.btn-red:hover {
	background-color: #FF4F65;
	color: #fff;
}

.btn-purple {
	color: #fff;
	background-color: #7D1DD1;
}

.btn-purple.selected {
	background-color: #7D1DD1;
}

.btn-purple:hover {
	background-color: #7D1DD1 !important;
}

.btn-default {
	min-width: 80px;
}

.btn-md {
	width: 130px;
}

.btn-x {
	min-width: 125px;
}

a:-webkit-any-link {
	cursor: pointer !important;
	text-decoration: none;
}

.ic.eye {
	margin-right: 5px;
}

.btn-solid-green.selected, .btn-solid-green {
	padding: 8px 22px;
	background-color: #07dcac !important;
	color: white !important;
}

.btn-solid-red:disabled {
	background-color: #FF4F65 !important;
	color: white !important;
}

.btn-solid-red.selected, .btn-solid-red, .btn-solid-red:hover {
	padding: 8px 22px;
	background-color: #FF4F65;
	color: white;
}

.btn-solid-blue, .btn-solid-blue:disabled {
	background-color: #2897C8 !important;
	color: white !important;
}

.btn-solid-blue.selected, .btn-solid-blue, .btn-solid-blue:hover {
	background-color: #2897C8 !important;
	color: white !important;
}

.btn-green {
	color: #07dcac;
	background-color: transparent;
	border: 1px solid #07dcac;
	opacity: 1 !important;
}

.btn-green:disabled {
	background-color: #07dcac !important;
	color: white !important;
}

.btn-green.selected, .btn-green:hover {
	background-color: #07dcac;
	color: white;
}

.btn-gray {
	color: #727588;
	background-color: transparent;
	border: 1px solid #727588;
	opacity: 1 !important;
}

.btn-gray.selected, .btn-gray:hover {
	background-color: #727588;
	color: white;
}

.btn-light-gray {
	color: #bcbfc8;
	background-color: transparent;
	border: 1px solid #bcbfc8;
	opacity: 1 !important;
}

.btn-light-gray.selected, .btn-light-gray:hover {
	background-color: #bcbfc8;
	color: white;
}

.btn-white {
	background-color: white;
	color: #7d1dd1;
}

.btn-outline {
	color: #7d1dd1;
	background-color: transparent;
	border: 1px solid #7d1dd1;
	opacity: 1 !important;
}

.btn-outline.selected, .btn-outline:hover {
	background-color: #7d1dd1;
	color: white;
}

.btn-blue {
	color: #0090ff;
	background-color: transparent;
	border: 1px solid #0090ff;
	opacity: 1 !important;
}

.btn-blue.selected, .btn-blue:hover {
	background-color: #0090ff;
	color: white;
}

.btn-dark-blue {
	color: #00477E;
	background-color: transparent;
	border: 1px solid #00477E;
	opacity: 1 !important;
}

.btn-dark-blue.selected, .btn-dark-blue:hover {
	background-color: #00477E;
	color: white;
}

.btn-pink {
	color: #ef49da;
	background-color: transparent;
	border: 1px solid #ef49da;
	opacity: 1 !important;
}

.btn-pink.selected, .btn-pink:hover {
	background-color: #ef49da;
	color: white;
}

.btn-orange {
	color: #ff6f00;
	background-color: transparent;
	border: 1px solid #ff6f00;
	opacity: 1 !important;
}

.btn-orange.selected, .btn-orange:hover {
	background-color: #ff6f00;
	color: white;
}

.btn-yellow {
	color: #D69608;
	background-color: transparent;
	border: 1px solid #D69608;
	opacity: 1 !important;
}

.btn-yellow.selected, .btn-yellow:hover {
	background-color: #D69608;
	color: white;
}

.btn-action {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	color: #7d1dd1;
	border-radius: 20px;
	border: none;
	cursor: pointer;
	font-size: 12px;
	padding: 6px 20px;
	text-transform: uppercase;
	white-space: nowrap;
	min-width: auto;
	font-weight: bold;
}

.btn-icon {
	padding: 7px 20px;
}

.btn-action:hover, .btn-action.selected {
	background-color: #7d1dd1;
	color: white;
}

.btn-file {
	overflow: hidden;
	position: relative;
	vertical-align: middle
}

.btn-file input {
	position: absolute;
	top: 30px;
	left: 68px;
	margin: 0;
	opacity: 0;
	filter: alpha(opacity=0);
	font-size: 23px;
	height: 100%;
	width: 100%;
	direction: ltr;
	cursor: pointer
}

.element {
	height: 100%;
	opacity: 0;
	left: 0;
	width: 100%;
	position: absolute !important;
	z-index: 999
}

.btn-loading {
	width: 18px;
	height: 18px;
	margin-left: 8px;
}

.btn.focus, .btn:focus {
	box-shadow: none;
}

.btn-icon-sm {
	width: 130px;
}

.btn-icon-md {
	width: 160px;
}

.btn-icon-md2 {
	width: 200px;
}

.btn-img {
	opacity: 0.5;
}

.btn-img.selected {
	opacity: 1 !important;
}

.btn-product-group {
	margin-right: 15px;
	padding: 6px 20px;
	min-width: 104px;
	text-transform: uppercase;
	opacity: 1 !important;
	background-color: transparent;
	white-space: nowrap;
}

.btn-product-group:hover, .btn-product-group.selected {
	color: white;
}
