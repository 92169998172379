.eye-table-responsive {
	margin-top: 1em;
	text-transform: uppercase;
	width: 100%;
	text-align: left;
	color: #737689;
	font-size: 13px;
}

.eye-table-responsive > .header {
	font-size: 13px !important;
}

.eye-table-responsive th {
	text-transform: uppercase !important;
	color: #8b2ad5 !important;
	font-size: 13px !important;
}

.eye-table-responsive th:first-child {
	padding: 13px 10px 13px 20px;
}

.eye-table-responsive th:not(:first-child) {
	padding: 13px 10px 13px 20px;
}

.eye-table-responsive th:last-child {
	padding: 10px 15px 10px 10px;
}

.eye-table-responsive th {
	text-transform: uppercase;
	font-size: 13px;
}

.eye-table-responsive.bold-with-total tr td:first-child {
	font-weight: bold;
	font-size: 13px;
}

.eye-table-responsive.bold-with-total tr:last-child td:first-child {
	color: #8b2ad5 !important;
}

.eye-table-responsive td:first-child {
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
	padding: 13px 10px 13px 20px;
}

.eye-table-responsive td:not(:first-child) {
	padding: 13px 10px 13px 20px;
}

.eye-table-responsive td:last-child {
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
}

.eye-table-responsive > tbody td {
	font-size: 13px;
	color: #737689;
}

.eye-table-responsive > tbody tr:nth-child(odd) > td {
	background-color: #e5e9ef
}

.eye-table-responsive tr {
	height: 27px;
}

@media only screen and (min-width: 768px) {
	.eye-table-responsive td:last-child {
		padding: 10px 15px 10px 10px;
	}
}

/*@media only screen and (max-width: 800px) {*/
	/*.eye-table-responsive {*/
		/*display: block;*/
	/*}*/

	/*.eye-table-responsive tr:nth-child(even) {*/
		/*background-color: transparent;*/
	/*}*/

	/*.eye-table-responsive tr {*/
		/*height: auto;*/
	/*}*/

	/*.eye-table-responsive .cf:after {*/
		/*visibility: hidden;*/
		/*display: block;*/
		/*font-size: 0;*/
		/*content: " ";*/
		/*clear: both;*/
		/*height: 0;*/
	/*}*/

	/*.eye-table-responsive * html .cf {*/
		/*zoom: 1;*/
	/*}*/

	/*.eye-table-responsive *:first-child + html .cf {*/
		/*zoom: 1;*/
	/*}*/

	/*.eye-table-responsive table {*/
		/*width: 100%;*/
		/*border-collapse: collapse;*/
		/*border-spacing: 0;*/
	/*}*/

	/*.eye-table-responsive th,*/
	/*.eye-table-responsive td {*/
		/*margin: 0;*/
		/*vertical-align: top;*/
	/*}*/

	/*.eye-table-responsive th {*/
		/*text-align: left;*/
		/*min-height: 1.25em;*/
	/*}*/

	/*.eye-table-responsive table {*/
		/*display: block;*/
		/*position: relative;*/
		/*width: 100%;*/
	/*}*/

	/*.eye-table-responsive thead {*/
		/*display: block;*/
		/*float: left;*/
	/*}*/

	/*.eye-table-responsive tbody {*/
		/*display: block;*/
		/*width: auto;*/
		/*position: relative;*/
		/*overflow-x: auto;*/
		/*white-space: nowrap;*/
	/*}*/

	/*.eye-table-responsive thead tr {*/
		/*display: block;*/
	/*}*/

	/*.eye-table-responsive th {*/
		/*display: block;*/
		/*text-align: right;*/
	/*}*/

	/*.eye-table-responsive tbody tr {*/
		/*display: inline-block;*/
		/*vertical-align: top;*/
	/*}*/

	/*.eye-table-responsive td {*/
		/*display: block;*/
		/*min-height: 1.25em;*/
		/*text-align: left;*/
	/*}*/

	/*!* sort out borders *!*/
	/*.eye-table-responsive th {*/
		/*border-bottom: 0;*/
		/*border-left: 0;*/
		/*border-bottom: 0;*/
		/*border-left: 0;*/
		/*text-align: left;*/
		/*width: 135px;*/
		/*white-space: nowrap;*/
		/*overflow: hidden;*/
		/*text-overflow: ellipsis;*/
		/*background-color: #e5e9ef;*/
		/*padding-left: 10px;*/
		/*display: flex;*/
		/*align-items: center;*/
		/*height: 30px;*/
	/*}*/

	/*.eye-table-responsive th > span {*/
		/*white-space: nowrap;*/
		/*overflow: hidden;*/
		/*text-overflow: ellipsis;*/
	/*}*/

	/*.eye-table-responsive td {*/
		/*border-left: 0;*/
		/*border-right: 0;*/
		/*border-bottom: 0;*/
		/*display: flex;*/
		/*align-items: center;*/
		/*height: 30px;*/
	/*}*/

	/*.eye-table-responsive tbody tr {*/
		/*padding-left: 15px;*/
		/*padding-right: 15px;*/
	/*}*/

	/*.eye-table-responsive td:first-child {*/
		/*color: #7d1dd1;*/
		/*font-weight: bold;*/
	/*}*/
/*}*/

table.white-table {
	width: 100%;
	font-family: Avenir-Heavy;
}

table.white-table > thead > tr > th {
	font-size: 15px;
	color: #fff;
	text-transform: uppercase;
}

table.white-table > thead > tr > th:not(:first-child) {
	padding: 12px;
}

table.white-table > thead > tr > th:first-child {
	padding: 12px 12px 12px 20px;
}

table.white-table.no-status > thead > tr > th:first-child {
	padding: 12px 12px 12px 35px;
}

table.white-table > tbody > tr > td {
	color: #fff;
	font-weight: normal;
	font-family: Avenir-Medium;
	font-size: 15px;
}

table.white-table > tbody > tr > td:not(:first-child) {
	padding: 7px 12px;
}

table.white-table > tbody > tr > td:first-child {
	padding: 0 12px 0 18px;
}

table.white-table.no-status > tbody > tr > td:first-child {
	padding: 0 12px 0 35px;
}

table.white-table > tbody > tr:nth-child(odd) {
	background-color: #9F6FD7;
}
