.small-icon {
	width: 14px !important;
	height: 22px !important;
}

.ic-sm {
	width: 20px !important;
	height: 20px !important;
}

.ic-sm2 {
	width: 22px;
	height: 22px;
}

.ic-xl {
	width: 50px;
	height: 50px;
}

.ic-round-xl {
	width: 50px;
	height: 50px;
	border-radius: 25px;
}

.ic-product-white {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/btn_Product_ON.svg) no-repeat center;
	background-size: contain;
	width: 24px;
	height: 24px;
}

.ic-md {
	width: 28px;
	height: 28px;
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
}

.ic-total, .item:hover > .ic-total {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_total_ON.svg) no-repeat;
	-webkit-mask-image: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_total_ON.svg);
	mask-image: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_total_ON.svg);
	color: white;
}

.ic-account, .item:hover > .ic-account {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_comandas_ON.svg) no-repeat;
	-webkit-mask-image: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_comandas_ON.svg);;
	mask-image: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_comandas_ON.svg);
}

.ic-product, .item:hover > .ic-product {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_produtos_ON.svg) no-repeat;
	-webkit-mask-image: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_produtos_ON.svg);
	mask-image: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_produtos_ON.svg);
}

.ic-shift, .item:hover > .ic-shift {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_operadores_ON.svg) no-repeat;
	-webkit-mask-image: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_operadores_ON.svg);
	mask-image: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_operadores_ON.svg);
}

.ic-operator, .item:hover > .ic-operator {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_view_operadores.svg) no-repeat;
	-webkit-mask-image: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_view_operadores.svg);
	mask-image: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_view_operadores.svg);
}

.ic-telemetry, .item:hover > .ic-telemetry {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_dispositivos_ON.svg) no-repeat;
	-webkit-mask-image: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_dispositivos_ON.svg);
	mask-image: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_dispositivos_ON.svg);
}

.ic-points, .item:hover > .ic-points {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_operacoes_ON.svg) no-repeat;
	-webkit-mask-image: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_operacoes_ON.svg);
	mask-image: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_operacoes_ON.svg);
}

.ic-detail, .item:hover > .ic-detail {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_detalhes_ON.svg) no-repeat;
	-webkit-mask-image: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_detalhes_ON.svg);
	mask-image: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_detalhes_ON.svg);
}

.ic-eyeplace, .item:hover > .ic-eyeplace {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_Eyeplace_ON.svg) no-repeat;
	-webkit-mask-image: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_Eyeplace_ON.svg);
	mask-image: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_Eyeplace_ON.svg);
}

.ic-cashless, .item:hover > .ic-cashless {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_Tags_ON.svg) no-repeat;
	-webkit-mask-image: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_Tags_ON.svg);
	mask-image: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_Tags_ON.svg);
}

.ic-config {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_config.svg) no-repeat center;
	width: 19px !important;
	height: 17px !important;
}

.ic-close {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Miscellaneous/btn_close_branco.svg) no-repeat center;
	width: 30px;
	height: 30px;
	cursor: pointer;
}

.ic-view {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_view.svg) no-repeat center;
	background-size: contain;
	width: 28px;
	height: 28px;
}

.ic-clock {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_clock.svg) no-repeat center;
	background-size: contain;
	width: 15px !important;
	height: 15px !important;
}

.ic-phone {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_phone.svg) no-repeat center;
	background-size: contain;
	width: 15px;
	height: 15px;
}

.ic-sync {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_sync.svg) no-repeat center;
	background-size: contain;
	width: 15px;
	height: 15px;
}

.ic-secure {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_secureid.svg) no-repeat center;
	background-size: contain;
	height: 15px;
}

.ic-lock {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_locked.svg) no-repeat center;
	background-size: contain;
	width: 11px;
	height: 15px;
}

.ic-lock-blue {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_lock_blue.svg) no-repeat center;
	background-size: contain;
	width: 16px !important;
	height: 16px !important;
}

.ic-unlock {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_unlocked.svg) no-repeat center;
	background-size: contain;
	width: 11px;
	height: 15px;
}

.ic-unlocked-white {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_unlocked_branco.svg) no-repeat center;
	background-size: contain;
	width: 11px;
	height: 15px;
}

.ic-point-device {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_operacao_device.svg) no-repeat center;
	background-size: contain;
	width: 17px;
	height: 16px;
}

.ic-edit {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_editar.svg) no-repeat center;
	background-size: contain;
	width: 15px;
	height: 15px;
}

.ic-add {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_add.svg) no-repeat;
	background-size: contain;
	width: 16px !important;
	height: 16px !important;
}

.ic-product-add {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_product_add.svg) no-repeat;
	background-size: contain;
	width: 36px !important;
	height: 36px !important;
}

.ic-access {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_acessar_branco.svg) no-repeat;
	background-size: contain;
	width: 16px !important;
	height: 16px !important;
}

.ic-eyeplace-white {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_eyeplace_branco.svg) no-repeat;
	background-size: contain;
	width: 16px !important;
	height: 15px !important;
}

.ic-edit-white {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_editar_branco.svg) no-repeat;
	background-size: contain;
	width: 14px !important;
	height: 14px !important;
}

.ic-dollar {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_valor_card.svg) no-repeat center;
	background-size: contain;
	width: 16px !important;
	height: 15px !important;
}

.ic-eyebank-purple {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_eyebank_logo.svg) no-repeat;
	background-size: contain;
	width: 50px;
	height: 33px;
}

.ic-extract {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_extrato.svg) no-repeat;
	background-size: contain;
	width: 17px;
	height: 12px;
}

.ic-anticipate {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_antecipar.svg) no-repeat;
	background-size: contain;
	width: 17px !important;
	height: 15px !important;
}

.ic-anticipate-purple {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_purple_antecipar.svg) no-repeat;
	background-size: contain;
	width: 17px !important;
	height: 15px !important;
}

.ic-rescue {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_resgatar.svg) no-repeat center;
	width: 9px !important;
	height: 14px !important;
}

.ic-setup {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_setup.svg) no-repeat;
	width: 28px;
	height: 28px;
}

.ic-eye {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_eye.svg) no-repeat;
	width: 17px !important;
	height: 11px !important;
}

.ic-support {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_suport.svg) no-repeat;
	width: 28px;
	height: 28px;
	background-size: contain;
}

.ic-logout {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_logout_branco.svg) no-repeat;
	width: 17px;
	height: 12px;
}

.ic-logout-tenant {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_sair_cliente.svg) no-repeat;
	width: 28px;
	height: 28px;
}

.ic-logout-tenant-white {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_sair_cliente_branco.svg) no-repeat;
	width: 28px;
	height: 28px;
}

.ic-user-white {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_user_branco.svg) no-repeat center;
	background-size: contain;
	width: 24px;
	height: 24px;
}

.ic-user-gray {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_user_gray.svg) no-repeat center;
	background-size: contain;
	width: 24px;
	height: 24px;
}

.ic-user-gray-small {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_user_gray.svg) no-repeat center;
	background-size: contain;
	width: 15px;
	height: 14px;
}

.ic-user-placeholder {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Miscellaneous/ic_cabeca_de_mudo.svg) no-repeat center;
	background-size: contain;
}

.ic-cpf {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_cpf.svg) no-repeat center;
	width: 19px !important;
	height: 13px !important;
}

.ic-company {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_empresa_branco.svg) no-repeat center center;
	width: 16px;
	height: 18px;
}

.ic-cam {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_camera.svg) no-repeat;
	width: 16px !important;
	height: 14px !important;
}

.ic-cam-white {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_camera_branco.svg) no-repeat;
	width: 16px !important;
	height: 14px !important;
}

.ic-error {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_error.svg) no-repeat center 2px;
	width: 20px !important;
	height: 20px !important;
}

.ic-save {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_salvar.svg) no-repeat;
	width: 13px !important;
	height: 14px !important;
}

.ic-success {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_check.svg) no-repeat center;
	width: 20px !important;
	height: 20px !important;
}

.tag-active {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Tags/tag_ativo.svg) no-repeat center;
	width: 70px !important;
	height: 70px !important;
}

.tag-inactive {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Tags/tag_inativo.svg) no-repeat center;
	width: 70px !important;
	height: 70px !important;
}

.tag-setup {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Tags/tag_setup.svg) no-repeat center;
	width: 70px !important;
	height: 70px !important;
	background-size: contain;
}

.tag-released {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Tags/tag_released.svg) no-repeat center;
	width: 70px !important;
	height: 70px !important;
	background-size: contain;
}

.tag-approval {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Tags/tag_aguardando_aprovacao.svg) no-repeat center;
	width: 70px !important;
	height: 70px !important;
	background-size: contain;
}

.tag-billed {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Tags/tag_closed.svg) no-repeat center;
	width: 70px !important;
	height: 70px !important;
	background-size: contain;
}

.tag-point {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Tags/tag_operacao.svg) no-repeat center;
	width: 70px !important;
	height: 70px !important;
	background-size: contain;
}

.tag-backstage {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Tags/tag_producao.svg) no-repeat center;
	width: 70px !important;
	height: 70px !important;
	background-size: contain;
}

.tag-consumer {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Tags/tag_consumidor.svg) no-repeat center;
	width: 70px !important;
	height: 70px !important;
	background-size: contain;
}

.ic-products {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_product.svg) no-repeat center;
	width: 15px !important;
	height: 15px !important;
}

.ic-folder {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_folder.svg) no-repeat center;
	width: 16px !important;
	height: 15px !important;
}

.ic-clone {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_clone.svg) no-repeat center;
	width: 14px !important;
	height: 14px !important;
}

.ic-purple-product {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_purple_product.svg) no-repeat center;
	width: 32px !important;
	height: 27px !important;
}

.ic-purple-money {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_purple_money_symbol.svg) no-repeat center;
	width: 28px !important;
	height: 30px !important;
}

.ic-money {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_money_symbol.svg) no-repeat center;
	width: 14px !important;
	height: 14px !important;
}

.ic-money-purple {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_money_symbol_purple.svg) no-repeat center;
	width: 14px !important;
	height: 14px !important;
}

.ic-purple-user {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_purple_user.svg) no-repeat center;
	width: 27px !important;
	height: 27px !important;
}

.ic-purple-profile {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_purple_profile.svg) no-repeat center;
	width: 30px !important;
	height: 27px !important;
}

.ic-blue-profile {
	background: url(//eyemobile-public.s3-sa-east-1.amazonaws.com/design/SaaS+Project/Icons/Admin/ic_blue_profile.svg) no-repeat center;
	width: 26px !important;
	height: 26px !important;
}

.ic-purple-email {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_purple_email.svg) no-repeat center;
	width: 27px !important;
	height: 27px !important;
}

.ic-purple-tag {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_purple_tag.svg) no-repeat center;
	width: 32px !important;
	height: 27px !important;
}

.ic-purple-calendar {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_purple_calendar.svg) no-repeat center;
	width: 32px !important;
	height: 27px !important;
}

.ic-purple-eyepay {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_purple_eyepay.svg) no-repeat center;
	width: 27px !important;
	height: 27px !important;
}

.ic-purple-percentage {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_purple_tax.svg) no-repeat center;
	width: 26px !important;
	height: 27px !important;
}

.ic-purple-phone {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_purple_phone.svg) no-repeat center;
	width: 32px !important;
	height: 27px !important;
}

.ic-purple-bank-name {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_bank_name.svg) no-repeat center;
	width: 32px !important;
	height: 27px !important;
}

.ic-purple-type {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_purple_type.svg) no-repeat center;
	width: 32px !important;
	height: 27px !important;
}

.ic-plus {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/btn_plus.svg) no-repeat center;
	width: 24px !important;
	height: 21px !important;
	background-size: contain;
}

.ic-tags {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Miscellaneous/ic_card.svg) no-repeat center;
}

.ic-tag-dark-gray {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_tag.svg) no-repeat center;
	background-size: contain;
	width: 16px;
	height: 18px;
}

.ic-list-tag-activated {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Timeline/ic_list_tag_activated.svg) no-repeat center;
	background-size: contain;
	width: 32px;
	height: 32px;
}

.ic-list-transactions-eyecashless {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Timeline/ic_list_transactions_eyecashless.svg) no-repeat center;
	background-size: contain;
	width: 32px;
	height: 32px;
}

.ic-list-transactions-transfer {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Timeline/ic_list_transactions_transfer.svg) no-repeat center;
	background-size: contain;
	width: 32px;
	height: 32px;
}

.ic-timeline-backstage-new-tag {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Timeline/ic_timeline_backstage_new_tag.svg) no-repeat center;
	background-size: contain;
	width: 32px;
	height: 32px;
}

.ic-timeline-backstage-purchase {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Timeline/ic_timeline_backstage_purchase.svg) no-repeat center;
	background-size: contain;
	width: 32px;
	height: 32px;
}

.ic-timeline-backstage-sale {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Timeline/ic_timeline_backstage_sale.svg) no-repeat center;
	background-size: contain;
	width: 32px;
	height: 32px;
}

.ic-timeline-backstage-tag-return {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Timeline/ic_timeline_backstage_tag_return.svg) no-repeat center;
	background-size: contain;
	width: 32px;
	height: 32px;
}

.ic-timeline-cancel {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Timeline/ic_timeline_cancel.svg) no-repeat center;
	background-size: contain;
	width: 32px;
	height: 32px;
}

.ic-timeline-cash-back {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Timeline/ic_timeline_cash_back.svg) no-repeat center;
	background-size: contain;
	width: 32px;
	height: 32px;
}

.ic-timeline-eyeticket {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Timeline/ic_timeline_eyeticket.svg) no-repeat center;
	background-size: contain;
	width: 32px;
	height: 32px;
}

.ic-timeline-purchase {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Timeline/ic_timeline_purchase.svg) no-repeat center;
	background-size: contain;
	width: 32px;
	height: 32px;
}

.ic-timeline-money-supply {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Timeline/ic_timeline_money_supply.svg) no-repeat center;
	background-size: contain;
	width: 32px;
	height: 32px;
}

.ic-timeline-money-withdrawal {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Timeline/ic_timeline_money_withdrawal.svg) no-repeat center;
	background-size: contain;
	width: 32px;
	height: 32px;
}

.ic-timeline-order {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Timeline/ic_timeline_order.svg) no-repeat center;
	background-size: contain;
	width: 32px;
	height: 32px;
}

.ic-timeline-sale {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Timeline/ic_timeline_sale.svg) no-repeat center;
	background-size: contain;
	width: 32px;
	height: 32px;
}

.ic-timeline-load-credit {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Timeline/ic_timeline_load_credit.svg) no-repeat center;
	background-size: contain;
	width: 32px;
	height: 32px;
}

.ic-timeline-tag-return {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Timeline/ic_timeline_tag_return.svg) no-repeat center;
	background-size: contain;
	width: 32px;
	height: 32px;
}

.ic-timeline-transfer_to {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Timeline/ic_timeline_transfer_to.svg) no-repeat center;
	background-size: contain;
	width: 32px;
	height: 32px;
}

.ic-more {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/btn_more.svg) no-repeat center;
	width: 40px;
	height: 18px;
}

.ic-close-red {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/btn_close.svg) no-repeat center;
	-webkit-background-size: contain;
	background-size: contain;
	width: 28px;
	height: 28px;
	cursor: pointer;
}

.ic-trash {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_trash_white.svg) no-repeat center;
	width: 13px;
	height: 15px;
}

.ic-product-check {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_product_check.svg) no-repeat center;
	width: 17px;
	height: 13px;
}

.ic-check-white {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_check_white.svg) no-repeat center;
	width: 17px;
	height: 13px;
}

.ic-product-cancel {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_product_cancel.svg) no-repeat center;
	width: 13px;
	height: 13px;
}

.ic-product-account {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/btn_Comanda_ON.svg) no-repeat center;
	width: 24px;
	height: 28px;
	background-size: contain;
}

.ic-email {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_email.svg) no-repeat center;
	width: 13px;
	height: 13px;
	-webkit-background-size: contain;
	background-size: contain;
}

.ic-dropdown-arrow-gray {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Miscellaneous/ic_arrow_down_gray.svg) no-repeat 50% !important;
	width: 30px;
	height: 30px;
}

.ic-qrcode {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_qrcode.svg) no-repeat center;
	width: 14px;
	height: 14px;
}

.ic-printer {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_print.svg) no-repeat center;
	width: 14px;
	height: 14px;
}

.ic-dropdown-arrow-white {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Miscellaneous/ic_arrow_down.svg) no-repeat 50% !important;
	width: 33px;
	height: 33px;
}

.tag-free {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Miscellaneous/tag_comanda_livre.svg) no-repeat center;
	width: 71px;
	height: 71px;
}

.tag-busy {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Miscellaneous/tag_comanda_ocupada.svg) no-repeat center;
	width: 71px;
	height: 71px;
}

.ic-point-purple {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_operacao_purple.svg) no-repeat center;
	width: 30px;
	height: 30px;
}

.ic-purple-refresh {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_purple_refresh.svg) no-repeat center;
	width: 18px;
	height: 18px;
	background-size: contain;
}

.ic-white-refresh {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_refresh_white.svg) no-repeat center;
	background-size: contain;
}

.ic-purple-mobile {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_purple_mobile.svg) no-repeat center;
	background-size: contain;
}

.ic-entry {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_lancamentos.svg) no-repeat center;
	width: 20px;
	height: 20px;
	background-size: 20px;
}

.ic-entry-purple {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_purple_lancamentos.svg) no-repeat center;
	width: 20px;
	height: 20px;
	background-size: 20px;
}

.ic-entry-placeholder {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/placeholder_eyeplace_detail.svg) no-repeat center;
	width: 75px;
	height: 100px;
	background-size: 75px;
}

.ic-purple-edit {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_purple_editar.svg) no-repeat center;
	width: 32px !important;
	height: 27px !important;
}

.ic-grey-edit {
	background: url(//eyemobile-public.s3-sa-east-1.amazonaws.com/design/SaaS+Project/Icons/Gerais/Btn_edit_gray.svg) no-repeat center;
	width: 28px !important;
	height: 28px !important;
}

.ic-file-grey {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_file_grey.svg) no-repeat center;
	background-size: contain;
	width: 30px;
	height: 16px;
}

.ic-file-white {
	background: url(//eyemobile-public.s3-sa-east-1.amazonaws.com/design/SaaS+Project/Icons/Gerais/ic_file_white.svg) no-repeat center;
	background-size: contain;
	width: 14px;
	height: 16px;
}

.tag-free {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Miscellaneous/tag_comanda_livre.svg) no-repeat center;
	width: 71px;
	height: 71px;
}

.tag-call {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Tags/tag_atendimento.svg) no-repeat center;
	width: 71px;
	height: 71px;
}

.tag-cashier {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Tags/tag_caixa.svg) no-repeat center;
	width: 71px;
	height: 71px;
}

.ic-fee {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_taxas.svg) no-repeat center;
	width: 15px;
	height: 15px;
}

.ic-permissions {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_permissions.svg) no-repeat center;
	width: 33px;
	height: 33px;
}

.ic-device-pink {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_device_pink.svg) no-repeat center;
	width: 33px;
	height: 33px;
}

.ic-file {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_file.svg) no-repeat center;
	width: 33px;
	height: 33px;
}

.ic-uncheck {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/check_grey_OFF.svg) no-repeat center;
	width: 30px;
	height: 30px;
}

.ic-check {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/check_green_ON.svg) no-repeat center;
	background-size: contain;
	width: 28px;
	height: 28px;
}

.ic-event-reproved {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_evento_recusado.svg) no-repeat center;
	width: 28px;
	height: 28px;
	background-size: contain;
}

.ic-no-participation {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/placeholder_sem_participacao.svg) no-repeat center;
	width: 40px;
	height: 40px;
	background-size: contain;
}

.ic-sidemenu-mobile {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_sidemenu_mobile.svg) no-repeat;
	width: 30px !important;
	height: 30px !important;
	background-size: contain;
}

.ic-eyeplace-sidebar {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_eyeplace_branco.svg) no-repeat;
	width: 24px !important;
	height: 24px !important;
	background-size: contain;
}

.ic-sidebar-dashboard {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Sidebar/ic_sidebar_painel.svg) no-repeat center;
	background-size: contain;
	width: 24px;
	height: 24px;
}

.ic-sidebar-point {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Sidebar/ic_sidebar_operacoes.svg) no-repeat center;
	width: 24px;
	height: 24px;
	background-size: contain;
}

.ic-sidebar-events {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Sidebar/ic_sidebar_eventos.svg) no-repeat center;
	width: 24px;
	height: 24px;
	background-size: contain;
}

.ic-sidebar-fiscal {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Sidebar/ic_sidebar_taxas.svg) no-repeat center;
	width: 24px;
	height: 24px;
	background-size: contain;
}

.ic-sidebar-statistics {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Sidebar/ic_sidebar_estatisticas.svg) no-repeat center;
	width: 24px;
	height: 24px;
	background-size: contain;
}

.ic-sidebar-eyebank {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Sidebar/ic_sidebar_eyebank.svg) no-repeat center;
	width: 26px;
	height: 26px;
	background-size: contain;
}

.ic-sidebar-goals {
	background: url(//eyemobile-public.s3-sa-east-1.amazonaws.com/design/SaaS+Project/Icons/Sidebar/ic_sidebar_metas.svg) no-repeat center;
	width: 26px;
	height: 26px;
	background-size: contain;
}

.ic-sidebar-secure {
	background: url(//eyemobile-public.s3-sa-east-1.amazonaws.com/design/SaaS+Project/Icons/Sidebar/ic_sidebar_secure.svg) no-repeat center;
	width: 26px;
	height: 26px;
	background-size: contain;
}

.ic-sidebar-report {
	background: url(//eyemobile-public.s3-sa-east-1.amazonaws.com/design/SaaS+Project/Icons/Sidebar/ic_sidebar_excel.svg) no-repeat center;
	width: 26px;
	height: 26px;
	background-size: contain;
}

.ic-warning {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_aviso.svg) no-repeat center;
	width: 30px;
	height: 30px;
	background-size: contain;
}

.ic-warning-red {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_aviso_red.svg) no-repeat center;
	width: 30px;
	height: 30px;
	background-size: contain;
}

.ic-download {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_download.svg) no-repeat;
	width: 17px !important;
	height: 15px !important;
}

.ic-download-gray {
	background: url(//eyemobile-public.s3-sa-east-1.amazonaws.com/design/SaaS+Project/Icons/Gerais/ic_download_gray.svg) no-repeat;
	-webkit-background-size: contain;
	background-size: contain;
	width: 28px !important;
	height: 28px !important;
}

.ic-purple-download {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_baixar_branco.svg) no-repeat;
	width: 26px !important;
	height: 26px !important;
	-webkit-background-size: contain;
	background-size: contain;
}

.ic-bell {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_bell.svg) no-repeat;
	width: 26px !important;
	height: 26px !important;
	-webkit-background-size: contain;
	background-size: contain;
}

.ic-bank {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_bank.svg) no-repeat center;
	width: 30px !important;
	height: 27px !important;
	background-size: contain;
}

.ic-minus {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/btn_minus.svg) no-repeat center;
	width: 18px !important;
	height: 18px !important;
	-webkit-background-size: contain;
	background-size: contain;
}

.ic-next-white {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/btn_white_next.svg) no-repeat center;
	width: 32px !important;
	height: 32px !important;
}

.ic-prev-white {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/btn_white_prev.svg) no-repeat center;
	width: 32px !important;
	height: 32px !important;
}

.ic-user-info {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_view_info_cadastral_ON.svg) no-repeat center;
	background-size: contain;
}

.ic-point-type {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_operacoes_ON.svg) no-repeat center;
	background-size: contain;
}

.ic-point-participation {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_percentuais_ON.svg) no-repeat center;
	background-size: contain;
}

.ic-point-user {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_operadores_ON.svg) no-repeat center;
	background-size: contain;
}

.ic-point-fiscal {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Views/ic_view_total_ON.svg) no-repeat center;
	background-size: contain;
}

.ic-round-email {
	background: url(//eyemobile-public.s3-sa-east-1.amazonaws.com/design/SaaS+Project/Icons/Views/ic_view_marketing.svg) no-repeat center;
	background-size: contain;
}

.ic-check-gray {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/btn_radio_ON.svg) no-repeat center;
	background-size: contain;
	height: 30px;
	width: 30px;
}

.ic-check-purple {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/btn_radio_purple_ON.svg) no-repeat center;
	background-size: contain;
	height: 30px;
	width: 30px;
}

.ic-alert-blue {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_alerta_azul.svg) no-repeat center;
	background-size: contain;
	height: 28px;
	width: 28px;
}

.ic-purple-pin {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_purple_pin.svg) no-repeat center;
	width: 30px !important;
	height: 30px !important;
}

.ic-document {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_doc.svg) no-repeat center;
	width: 16px!important;
	height: 18px!important;
}

.ic-purple-document {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_purple_doc.svg) no-repeat center;
	width: 30px !important;
	height: 30px !important;
}

.ic-view-white {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_view_branco.svg) no-repeat center;
	width: 30px !important;
	height: 30px !important;
}

.ic-card-white {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_card_branco.svg) no-repeat center;
	width: 20px !important;
	height: 15px !important;
}

.ic-statistic-list {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_estatistica_lista.svg) no-repeat center;
	width: 50px !important;
	height: 32px !important;
	-webkit-background-size: contain;
	background-size: contain;
}

.ic-statistic-pizza {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_estatistica_pizza.svg) no-repeat center;
	width: 50px !important;
	height: 32px !important;
	-webkit-background-size: contain;
	background-size: contain;
}

.ic-statistic-bar {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_estatistica_barra.svg) no-repeat center;
	width: 50px !important;
	height: 32px !important;
	-webkit-background-size: contain;
	background-size: contain;
}

.ic-statistic-line {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_estatistica_linha.svg) no-repeat center;
	width: 50px !important;
	height: 32px !important;
	-webkit-background-size: contain;
	background-size: contain;
}

.ic-cancel-purple {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_purple_cancelar.svg) no-repeat center;
	width: 13px !important;
	height: 13px !important;
	-webkit-background-size: contain;
	background-size: contain;
}

.placeholder-statistics {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/Placeholder_Estatistica.svg) no-repeat center;
	-webkit-background-size: contain;
	background-size: contain;
	height: 350px;
	width: 350px;
}

.placeholder-fiscal {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/Placeholder_Fiscal.svg) no-repeat center;
	-webkit-background-size: contain;
	background-size: contain;
	height: 350px;
	width: 350px;
}

.placeholder-statistics-entries {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/placeholder_estatistica_lancamentos.svg) no-repeat center;
	-webkit-background-size: contain;
	background-size: contain;
	height: 122px;
	width: 90px;
}

.placeholder-statistics-paytypes {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/placeholder_estatistica_pagamentos.svg) no-repeat center;
	-webkit-background-size: contain;
	background-size: contain;
	height: 122px;
	width: 90px;
}

.placeholder-statistics-fiscal {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/placeholder_estatistica_taxas.svg) no-repeat center;
	-webkit-background-size: contain;
	background-size: contain;
	height: 122px;
	width: 90px;
}

.tag-open {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Tags/tag_aberto.svg) no-repeat center;
}

.tag-closed {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Tags/tag_fechado.svg) no-repeat center;
}

.tag-closed-manually {
	background: url(//eyemobile-public.s3-sa-east-1.amazonaws.com/design/SaaS+Project/Icons/Tags/tag_fechado_manualmente.svg) no-repeat center;
}

.ic-key {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_key.svg) no-repeat center;
	background-size: contain;
	width: 17px;
	height: 17px;
}

.ic-open-shift {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_caixa_aberto.svg) no-repeat center;
	background-size: contain;
	height: 18px;
	width: 15px;
}

.ic-closed-shift {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_caixa_fechado.svg) no-repeat center;
	background-size: contain;
	height: 18px;
	width: 15px;
}

.ic-gray-device {
	background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Gerais/ic_grey_device.svg) no-repeat center;
	background-size: contain;
	height: 20px;
	width: 13px;
}

.ic-replace {
	background: url(//eyemobile-public.s3-sa-east-1.amazonaws.com/design/SaaS+Project/Icons/Gerais/ic_replace.svg) no-repeat center;
	background-size: contain;
	width: 16px !important;
	height: 16px !important;
}

.ic-devices {
	background: url(//eyemobile-public.s3-sa-east-1.amazonaws.com/design/SaaS+Project/Icons/Admin/ic_sidebar_admin_home.svg) no-repeat center;
	background-size: contain;
	width: 30px;
	height: 24px;
}

.ic-exchange {
	background: url(//eyemobile-public.s3-sa-east-1.amazonaws.com/design/SaaS+Project/Icons/Gerais/ic_exchange.svg) no-repeat center;
	background-size: contain;
	width: 25px;
	height: 25px;
}

.ic-purple-password {
	background: url(//eyemobile-public.s3-sa-east-1.amazonaws.com/design/SaaS+Project/Icons/Gerais/ic_purple_password.svg) no-repeat center;
	background-size: contain;
	width: 26px;
	height: 26px;
}

.ic-blue-password {
	background: url(//eyemobile-public.s3-sa-east-1.amazonaws.com/design/SaaS+Project/Icons/Gerais/ic_blue_password.svg) no-repeat center;
	background-size: contain;
	width: 26px;
	height: 26px;
}

.ic-dcs {
	background: url(//eyemobile-public.s3-sa-east-1.amazonaws.com/design/SaaS+Project/Icons/Admin/ic_sidebar_admin_cds.svg) no-repeat center;
	background-size: contain;
	width: 26px;
	height: 26px;
}

.ic-blue-round-device {
	background: url(//eyemobile-public.s3-sa-east-1.amazonaws.com/design/SaaS+Project/Icons/Admin/ic_admin_modelos.svg) no-repeat center;
	background-size: contain;
	width: 26px;
	height: 26px;
}

.ic-white-round-device {
	background: url(//eyemobile-public.s3-sa-east-1.amazonaws.com/design/SaaS+Project/Icons/Admin/ic_admin_modelos_white.svg) no-repeat center;
	background-size: contain;
	width: 26px;
	height: 26px;
}

.ic-blue-round-currency {
	background: url(//eyemobile-public.s3-sa-east-1.amazonaws.com/design/SaaS+Project/Icons/Admin/ic_admin_adquirentes.svg) no-repeat center;
	background-size: contain;
	width: 26px;
	height: 26px;
}

.ic-white-round-currency {
	background: url(//eyemobile-public.s3-sa-east-1.amazonaws.com/design/SaaS+Project/Icons/Admin/ic_admin_adquirentes_white.svg) no-repeat center;
	background-size: contain;
	width: 26px;
	height: 26px;
}

.ic-blue-round-serialNumber {
	background: url(//eyemobile-public.s3-sa-east-1.amazonaws.com/design/SaaS+Project/Icons/Admin/ic_admin_snumber.svg) no-repeat center;
	background-size: contain;
	width: 26px;
	height: 26px;
}

.ic-white-round-serialNumber {
	background: url(//eyemobile-public.s3-sa-east-1.amazonaws.com/design/SaaS+Project/Icons/Admin/ic_admin_snumber_white.svg) no-repeat center;
	background-size: contain;
	width: 26px;
	height: 26px;
}

.ic-blue-round-dcs {
	background: url(//eyemobile-public.s3-sa-east-1.amazonaws.com/design/SaaS+Project/Icons/Admin/ic_admin_cds.svg) no-repeat center;
	background-size: contain;
	width: 26px;
	height: 26px;
}

.ic-white-round-dcs {
	background: url(//eyemobile-public.s3-sa-east-1.amazonaws.com/design/SaaS+Project/Icons/Admin/ic_admin_cds_white.svg) no-repeat center;
	background-size: contain;
	width: 26px;
	height: 26px;
}

.ic-no-results {
	width: 56px;
	height: 56px;
	color: #C0C0C0;
	background: url(../assets/ic_attention.svg) no-repeat center;
}

.ic-union {
	width: 16px;
	height: 16px;
	color: #C0C0C0;
	background: url(../assets/ic_union.svg) no-repeat center;
}
