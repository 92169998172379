label.eye-radio-btn-container {
	display: flex;
	align-items: center;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

input.eye-radio-btn {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

label.eye-radio-btn-container > .checkmark {
	position: relative;
	height: 26px;
	width: 26px;
	border-radius: 50%;
	border: 1px solid #737689;
}

label.eye-radio-btn-container.purple > .checkmark {
	border: 1px solid #9013FE;
}

input.eye-radio-btn ~ .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

input.eye-radio-btn:checked ~ .checkmark:after {
	display: block;
}

label.eye-radio-btn-container > .checkmark:after {
	top: 3px;
	left: 3px;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background: #737689;
}

label.eye-radio-btn-container.purple > .checkmark:after {
	background: #9013FE;
}
