.m0 {
	margin: 0 !important;
}

.m0-auto {
	margin: 0 auto;
}

.mtb-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.mt-2 {
	margin-top: 2px !important;
}

.mt-sm {
	margin-top: 5px !important;
}

.mt-7 {
	margin-top: 7px !important;
}

.mt {
	margin-top: 10px !important;
}

.mt-md {
	margin-top: 15px !important;
}

.mt-lg {
	margin-top: 20px !important;
}

.mt-xl {
	margin-top: 25px !important;
}

.mt-minus{
	margin-top: -3em;
}

.mt-30 {
	margin-top: 30px !important;
}

.mt-40 {
	margin-top: 40px !important;
}

.mt-70 {
	margin-top: 70px !important;
}

.mt-mx {
	margin-top: 2em !important;
}

.mt0 {
	margin-top: 0 !important;
}

.ml0 {
	margin-left: 0 !important;
}

.ml-sm {
	margin-left: 5px !important;
}

.ml {
	margin-left: 10px !important;
}

.ml-md {
	margin-left: 15px !important;
}

.ml-lg {
	margin-left: 20px !important;
}

.ml-xl {
	margin-left: 25px !important;
}

.ml-30 {
	margin-left: 30px !important;
}

.ml-23 {
	margin-left: 23px;
}

.ml-35 {
	margin-left: 35px;
}

.ml-36 {
	margin-left: 36px !important;
}

.mb0 {
	margin-bottom: 0 !important;
}

.mb-sm {
	margin-bottom: 5px !important;
}

.mb {
	margin-bottom: 10px !important;
}

.mb-md {
	margin-bottom: 15px !important;
}

.mb-lg {
	margin-bottom: 20px !important;
}

.mb-xl {
	margin-bottom: 25px !important;
}

.mb-30 {
	margin-bottom: 30px !important;
}

.mb-40 {
	margin-bottom: 40px !important;
}

.mb-70 {
	margin-bottom: 70px !important;
}

.mb-150 {
	margin-bottom: 150px;
}

.mr0 {
	margin-right: 0 !important;
}

.mr2 {
	margin-right: 2px !important;
}

.mr-sm {
	margin-right: 5px !important;
}

.mr {
	margin-right: 10px !important;
}

.mr-md {
	margin-right: 15px !important;
}

@media only screen and (min-width: 480px) {
	.mr-b-md {
		margin-right: 15px ;
	}
}

.mr-lg {
	margin-right: 20px !important;
}

.mr-35 {
	margin-right: 35px !important;
}

.mr-xl{
	margin-right: 40px !important;
}

.mr-40 {
	margin-right: 40px !important;
}

.mr-60 {
	margin-right: 60px !important;
}

.p-l-md {
	padding-left: 2.5em;
}

.m-t-mx {
	margin-top: 2em;
}

.m-l-sm {
	margin-left: 1em;
}

.m-b-sm {
	margin-bottom: 1em;
}

@media(max-width: 768px) {
	.ml-lg-xs {
		margin-left: 20px !important;
	}

	.mr0-xs {
		margin-right: 0 !important;
	}

	.mb-md-xs {
		margin-bottom: 15px !important;
	}

	.mt0-xs {
		margin-top: 0 !important;
	}

	.mt-xs-xs {
		margin-top: 5px !important;
	}

	.mt-xs {
		margin-top: 10px !important;
	}

	.mt-md-xs {
		margin-top: 15px !important;
	}

	.mt-xl-xs {
		margin-top: 25px !important;
	}

	.mr0-xs {
		margin-right: 0 !important;
	}

	.ml0-xs {
		margin-left: 0 !important;
	}

	.mr-xs {
		margin-right: 10px !important;
	}
}
