
.multi-select input {
    border: 1px solid rgb(222, 226, 228) !important;
}

.loading-multi {
	position: absolute;
	z-index: 9999;
	right: 13px;
	top: 43px;
}

.multi-select {
    width: 260px;
    position: relative;
}

.eye-multi-select.md .multi-select {
	width: 335px;
}

@media(width: 1366px) {
	.eye-multi-select.md .multi-select {
		width: 260px;
	}
}

.dropdown {
    font-size: 12px !important;
}

.item-renderer {
    display: flex !important;
    align-items: center !important;
}

.item-renderer {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdown-heading {
    background-color: rgb(114, 117, 136) !important;
    border-radius: 25px !important;
    border: none !important;
    height: 35px !important;
    transition: all 0.1s ease;
}

.dropdown[aria-expanded=true] > .dropdown-heading {
    border-radius: 20px 20px 0 0 !important;
}

.dropdown-heading-value {
    padding-left: 15px !important;
    padding-right: 30px !important;
    color: white !important;
    text-align: center !important;
    white-space: nowrap !important;
}

.dropdown-heading-value > span {
    color: white !important;
}

.dropdown-heading-dropdown-arrow {
    background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Miscellaneous/ic_arrow_down.svg) no-repeat center !important;
    width: 40px !important;
}

.dropdown-heading-dropdown-arrow > span {
    display: none !important;
}

.dropdown-content {
    background-color: #727588 !important;
    border: none !important;
    transition: all 0.1s ease;
    border-radius: 0 0 10px 10px !important;
    z-index: 9999 !important;
}

.dropdown-content input{
    border-radius: 15px !important;
}

.select-item[aria-selected=false]{
    background-color: rgb(114, 117, 136) !important;
    color: white !important;
}
.select-panel > .select-item, .select-item:hover, .select-item[aria-selected=true] {
    background-color: #ffffff1a !important;
}

.multi-select .select-item .item-renderer span {
	font-size: 14px;
}

.multi-select .dropdown-heading-value {
	font-size: 12px;
	text-transform: uppercase;
}

/* Base for label styling */
.item-renderer > [type="checkbox"]:not(:checked),
.item-renderer > [type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
}
.item-renderer > [type="checkbox"]:not(:checked) + span,
.item-renderer > [type="checkbox"]:checked + span {
    position: relative;
    padding-left: 1.95em !important;
    cursor: pointer !important
}

/* checkbox aspect */
.item-renderer > [type="checkbox"]:not(:checked) + span:before,
.item-renderer > [type="checkbox"]:checked + span:before {
    content: '';
    position: absolute;
    left: 0; top: 0;
    /*width: 1.35em; height: 1.35em;*/
    width: 0;
    height: 0;
    background: #fff;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
}

/* checked mark aspect */
.item-renderer > [type="checkbox"]:not(:checked) + span:after,
.item-renderer > [type="checkbox"]:checked + span:after {
    content: '✔';
    position: absolute;
    top: 0.3em;
    left: 0.1em;
    font-size: 1.4em;
    line-height: 0.8;
    color: #ffffff;
    transition: all .2s;
    font-family: Helvetica, Arial, sans-serif;
}

/* checked mark aspect changes */
.item-renderer > [type="checkbox"]:not(:checked) + span:after {
    opacity: 0;
    transform: scale(0);
}

.item-renderer > [type="checkbox"]:checked + span:after {
    opacity: 1;
    transform: scale(1);
}

/* disabled checkbox */
.item-renderer > [type="checkbox"]:disabled:not(:checked) + span:before,
.item-renderer > [type="checkbox"]:disabled:checked + span:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ddd;
}
.item-renderer > [type="checkbox"]:disabled:checked + span:after {
    color: #999;
}

.select-item > [type="checkbox"]:disabled + span {
    color: #aaa;
}

/* accessibility */
.item-renderer > [type="checkbox"]:checked:focus + span:before,
.item-renderer > [type="checkbox"]:not(:checked):focus + span:before {
    /*border: 2px dotted blue;*/
}

.dropdown-content::-webkit-scrollbar {
    width: 10px;
    background-color: #727588;
}

.dropdown-content::-webkit-scrollbar-thumb {
    background-color: #ffffff3b;
}

.dropdown-content::-webkit-scrollbar-track {
    background-color: #808394;
}

.multi-select-left .dropdown-heading-value {
	text-align: left !important;
	padding-left: 18px !important;
}

@media(max-width: 768px) {
	.multi-select {
		width: 220px;
	}
}

.react-select__value-container.react-select__value-container--is-multi.react-select__value-container--has-value {
	padding: 6px 8px !important;
}

.react-select__multi-value {
	color: #ffffff;
	border-radius: 12px !important;
	background-color: #A5A7B3 !important;
}

.react-select__multi-value__remove {
	cursor: pointer !important;
	-webkit-border-radius: 0 12px 12px 0 !important;
	-moz-border-radius: 0 12px 12px 0 !important;
	border-radius: 0 12px 12px 0 !important;
}

.react-select__multi-value__remove:hover {
	background-color: #9396a0 !important;
	color: inherit !important;
}

.react-select__multi-value__label {
	color: #ffffff !important;
}
