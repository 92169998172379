.eye-card {
	position: relative;
	font-size: 12px;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding: 15px;
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: #fff;
	border-radius: 12px;
	margin: 20px 15px 0 15px;
	-webkit-box-shadow: 0 2px 5px 0 #73768944;
	-moz-box-shadow: 0 2px 5px 0 #73768944;
	box-shadow: 0 2px 5px 0 #73768944;
	color: #737689;
}

.eye-card textarea {
    color: #737689 !important;
}

.eye-card .line {
    width: 100%;
    margin-top: 7px;
    padding-left: 15px;
    padding-right: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.eye-card > .line .name {
    color: #737689;
    display: flex;
	align-items: center;
}

.content-card {
    display: flex;
	flex: 1;
    padding-bottom: 20px;
    flex-wrap: wrap;
    margin-left: -15px;
	margin-right: -15px;
}

.internal-border {
    width: 100%;
    border: 1px solid #e0e2e8;
    border-radius: 15px;
    padding: 10px;
}

.internal-border .name {
    color: #737689 !important;
}

.eye-card-header {
    display: flex;
    width: 100%;
    font-size: 14px;
    align-items: center;
    color: #737689 !important;
    text-transform: uppercase;
}

.content-card-modal {
    max-height: 328px;
    margin-top: 10px;
	margin-right: 0;
}

.border-radius-card {
  border-radius: 12px !important;
}

.fix-min-height{
    min-height: 387px;
}

@media(max-width: 768px) {
	.content-card {
		justify-content: center;
	}
}
