.p20{
	padding: 20px !important;
}

.p0 {
	padding: 0 !important;
}

.pt0 {
	padding-top: 0 !important;
}

.pt2 {
	padding-top: 2px !important;
}

.pt-sm {
	padding-top: 5px !important;
}

.pt {
	padding-top: 10px !important;
}

.pt-md {
	padding-top: 15px !important;
}

.pt-lg {
	padding-top: 20px !important;
}

.pl0 {
	padding-left: 0 !important;
}

.pl-sm {
	padding-left: 5px !important;
}

.pl {
	padding-left: 10px !important;
}

.pl-md {
	padding-left: 15px !important;
}

.pl-lg {
	padding-left: 20px !important;
}

.pl-xl {
	padding-left: 25px !important;
}

.pl-30 {
	padding-left: 30px !important;
}

.pl-40 {
	padding-left: 40px !important;
}

.pb0 {
	padding-bottom: 0 !important;
}

.pb-sm {
	padding-bottom: 5px !important;
}

.pb {
	padding-bottom: 10px !important;
}

.pb-md {
	padding-bottom: 15px !important;
}

.pb-lg {
	padding-bottom: 20px !important;
}

.pb-xl {
	padding-bottom: 25px !important;
}

.pb-30 {
	padding-bottom: 30px !important;
}

.pr0 {
	padding-right: 0 !important;
}

.pr-sm {
	padding-right: 5px !important;
}

.pr {
	padding-right: 10px !important;
}

.pr-md {
	padding-right: 15px !important;
}

.pr-lg {
	padding-right: 20px !important;
}

.pr-xl {
	padding-right: 25px !important;
}

.pr-30 {
	padding-right: 30px !important;
}

.pr-40 {
	padding-right: 40px !important;
}

@media (max-width: 768px) {
	.xs-pl-lg {
		padding-left: 20px !important;
	}

	.pr0-xs {
		padding-right: 0 !important;
	}

	.pl0-xs {
		padding-left: 0 !important;
	}
}
