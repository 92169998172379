/* Single select custom styles */

.Select.center-uppercase .Select-placeholder {
	text-transform: uppercase;
	text-align: center;
	padding-left: 10px;
}

.Select.center-uppercase .Select-value {
	text-transform: uppercase;
	text-align: center;
}

.Select.padding .Select-input {
	padding-left: 15px;
}

.Select.gray.is-focused.is-open > .Select-control {
	border-radius: 20px 20px 0 0 !important;
}

.Select.md {
	width: 300px;
}

.Select-option {
	white-space: normal;
	-ms-word-break: break-word;
	word-break: break-word;
}

.Select-option,
.Select-noresults {
	text-transform: none;
}

.Select-control,
.Select.gray .Select-control {
	transition: all .2s ease;
}

.Select-option.is-disabled {
	cursor: default;
	padding-left: 1em;
	font-weight: bold;
}

.Select.uppercase .Select-placeholder,
.Select.uppercase .Select-value {
	text-transform: uppercase;
}

.Select-value {
	line-height: 33px;
}

.Select-control {
	height: 33px;
}

.Select-input {
	height: 33px;
}

.Select.single-select .Select-control {
	border-top: 0;
	border-left: 0;
	border-right: 0;
	border-radius: 0;
}

.transparent-select .Select-clear {
	color: #ffffff;
}

.transparent-select {
	background-color: transparent;
	border-color: white !important;
}

.transparent-select .Select-value-label {
	color: white !important;
	font-family: Avenir-Medium;
}

.transparent-select .Select-control {
	border: none !important;
	background-color: transparent !important;
	border-bottom: 1px solid hsla(0, 0%, 100%, .27) !important;
}

.transparent-select .Select.is-focused:not(.is-open) > .Select-control {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	background-color: transparent !important;
}

.transparent-select .Select-input > input {
	text-align: left !important;
	width: 100% !important;
	font-weight: normal !important;
	font-family: Avenir-Medium !important;
}

.transparent-select .Select-placeholder {
	color: white !important;
}

.Select.transparent-select.is-focused.is-open > .Select-control {
	-webkit-border-radius: 0 !important;
	-moz-border-radius: 0 !important;
	border-radius: 0 !important;
}

.Select.is-disabled .Select-value-label {
	opacity: 0.5;
}

.Select.gray .Select-control {
	border-radius: 25px !important;
	background-color: #737689;
}

.Select.gray .Select-option,
.Select.gray .Select-option.is-focused {
	background-color: #737689 !important;
	color: #fff !important;
}

.Select.gray.is-focused:not(.is-open) > .Select-control {
	background-color: #737689;
	border: none;
}

.Select-input > input,
.Select.gray .Select-input > input {
	line-height: 20px;
}

.Select.gray .Select-input > input {
	color: #fff !important;
}

.Select.gray .Select-control {
	border: none;
}

.Select.gray > .Select-control .Select-value {
	line-height: 32px;
}

.Select.gray > .Select-control .Select-value-label {
	color: #fff !important;
	padding-left: 18px !important;
	text-transform: uppercase;
	font-size: 12px !important;
}

.Select-placeholder,
.Select-value-label,
.Select.gray .Select-placeholder {
	line-height: 34px;
	font-size: 12px;
}

.Select.gray .Select-placeholder {
	padding-left: 18px;
	color: #fff;
}

.Select.gray .Select-menu-outer {
	border: none;
}

/**------------------------**/
/**         New            **/
/**------------------------**/

.eye-select-container {
	width: 100%;
}

.eye-select__control {
	height: 35px;
	transition: all .2s ease;
	min-height: 33px !important;
	border-top: 0 !important;
	border-left: 0 !important;
	border-right: 0 !important;
	border-radius: 0 !important;
	border-bottom: 1px solid #a9a9a9 !important;
}

.eye-select__control.eye-select__control--is-focused {
	border-color: #a9a9a9 !important;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.eye-select__menu-list {
	font-size: 12px;
}

.eye-select__option {
	font-family: Avenir-Medium;
	font-size: 12px !important;
	white-space: normal;
	-ms-word-break: break-word;
	word-break: break-word;
	cursor: pointer !important;
}

.eye-select__indicators {
	padding-right: 5px;
	cursor: pointer;
}

.eye-select__placeholder {
	height: 33px;
	font-size: 12px;
	min-height: 33px;
	line-height: 32px;
	font-family: Avenir-Medium;
}

.eye-select__value-container {
	height: 33px;
	font-size: 12px;
	min-height: 33px;
	padding: 0 0 !important;
	font-family: Avenir-Medium;
}

.eye-select__value-container > span,
.eye-select__value-container > div {
	margin: 0;
	padding-top: 0;
	padding-bottom: 0;
	text-transform: uppercase;
}

.eye-select__input {
	border: 0;
	font-size: 12px;
	line-height: 32px;
	height: 32px;
	color: #737689 !important;
}

.eye-select__single-value {
	font-family: Avenir-Medium;
	padding-left: 0;
	font-size: 12px;
}

.eye-select-container.text-center .eye-select__placeholder,
.eye-select-container.text-center .eye-select__single-value {
	text-align: center;
	width: 100%;
}

.eye-select-container .eye-select__single-value {
	color: #737689 !important;
}

.eye-select-container .eye-select__option.eye-select__option--is-selected {
	color: #fff !important;
	background-color: #7D1DD1;
}

.eye-select-container .eye-select__option.eye-select__option--is-focused:not(.eye-select__option--is-selected) {
	background-color: rgba(255, 0, 247, .08)!important;
}

.eye-select-container.md {
	width: 300px;
}

.eye-select__clear-indicator {
	padding: 6px 0 6px !important;
}

@media(max-width: 768px) {
	.eye-select__value-container > span {
		font-size: 11px;
	}
}

/**------------------------**/
/**         Default        **/
/**------------------------**/

.react-select-container {
	width: 100%;
}

.react-select__control {
	min-height: 36px !important;
	transition: all .2s ease;
	border-top: 0 !important;
	border-left: 0 !important;
	border-right: 0 !important;
	border-radius: 0 !important;
	border-bottom: 1px solid #a9a9a9 !important;
}

.react-select__control.react-select__control--is-focused {
	border-color: #a9a9a9 !important;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.react-select__menu-list {
	font-size: 12px;
}

.react-select__option {
	font-family: Avenir-Medium;
	white-space: normal;
	margin-bottom: 3px;
	word-break: break-word;
	-ms-word-break: break-word;
	font-size: 14px !important;
	cursor: pointer !important;
	padding-left: 20px !important;
}

.react-select__indicators {
	padding-right: 5px;
	cursor: pointer;
}

.react-select__placeholder {
	height: 33px;
	font-size: 12px;
	min-height: 33px;
	line-height: 32px;
	font-family: Avenir-Medium;
}

.react-select__value-container {
	font-size: 12px;
	font-family: Avenir-Medium;
}

.react-select__value-container > span,
.react-select__value-container > div {
	padding-top: 0;
	padding-bottom: 0;
	text-transform: uppercase;
}

.react-select__input {
	border: 0;
	font-size: 12px;
	color: #737689 !important;
}

.react-select__single-value {
	font-family: Avenir-Medium;
	padding-left: 0;
	font-size: 12px;
}

.react-select-container.text-center .react-select__placeholder,
.react-select-container.text-center .react-select__single-value {
	text-align: center;
	width: 100%;
}

.react-select-container .react-select__single-value {
	color: #737689 !important;
}

.react-select-container .react-select__option.react-select__option--is-selected {
	color: #fff !important;
	background-color: #7D1DD1;
}

.react-select-container .react-select__option.react-select__option--is-focused:not(.react-select__option--is-selected) {
	background-color: rgba(40, 151, 200, 0.08)!important;
}

.react-select-container .react-select__option.react-select__option--is-focused:not(.react-select__option--is-selected).is-associated {
	background-color: white !important;
	color: #BBBBBB;
	pointer-events: none;
}
.react-select-container .react-select__option:not(.react-select__option--is-selected).is-associated {
	background-color: white !important;
	color: #BBBBBB;
	pointer-events: none;
}

.react-select-container {
	width: 300px;
}

.react-select-container.sm {
	width: 280px;
}

.react-select-container.md {
	width: 335px;
}

.react-select__clear-indicator {
	padding: 6px 0 6px !important;
}

@media(max-width: 768px) {
	.react-select__value-container > span {
		font-size: 11px;
	}
}

.react-select__menu {
	z-index: 2 !important;
}

/**------------------------**/
/**          Dark          **/
/**------------------------**/

.react-select-container.dark .react-select__input,
.react-select-container.dark .react-select__placeholder,
.react-select-container.dark .react-select__single-value,
.react-select-container.dark .react-select__menu-notice,
.react-select-container.dark .react-select__value-container > span {
	color: #fff !important;
}

.react-select-container.dark .react-select__option.react-select__option--is-focused {
	background-color: #ffffff1a !important;
}

.react-select-container.dark .react-select__control {
	border-width: 0 !important;
	border-radius: 25px !important;
	background-color: #737689;
}

.react-select-container.dark .react-select__control.react-select__control--menu-is-open {
	border-radius: 20px 20px 0 0 !important;
}

.react-select-container.dark .react-select__value-container {
	padding-left: 13px !important;
}

.react-select-container.dark .react-select__menu {
	margin-top: 0;
	margin-bottom: 0;
	border-radius: 0;
	box-shadow: none;
}

.react-select-container.dark .react-select__option {
	background-color: #737689 !important;
	color: #fff !important;
}

.react-select-container.dark .react-select__menu-list {
	background-color: #737689 !important;
	color: #fff !important;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	padding-top: 0;
	padding-bottom: 0;
}

.react-select-container.dark .react-select__clear-indicator {
	color: #fff;
}

/**------------------------**/
/**          White         **/
/**------------------------**/

.react-select-container.white .react-select__control {
	border: none !important;
	background-color: transparent !important;
	border-bottom: 1px solid hsla(0, 0%, 100%, .27) !important;
}

.react-select-container.white .react-select__input,
.react-select-container.white .react-select__single-value {
	color: #fff !important;
}

.react-select-container.white .react-select__option,
.react-select-container.white .react-select__menu-list,
.react-select-container.white .react-select__value-container > span {
	color: #737689;
}

.react-select-container.white .react-select__option.react-select__option--is-focused:not(.react-select__option--is-selected) {
	background-color: rgba(255, 0, 247, .08)!important;
}

.react-select-container.white .react-select__placeholder {
	color: #fff;
}

/**------------------------**/
/**       Transparent      **/
/**------------------------**/

.react-select-container.transparent .react-select__control {
	border: none !important;
	background-color: transparent !important;
	border-bottom: 1px solid #a9a9a9 !important;
}

.react-select-container.transparent .react-select__single-value,
.react-select-container.transparent .react-select__menu-list,
.react-select-container.transparent .react-select__value-container > span,
.react-select-container.transparent .react-select__placeholder > span,
.react-select-container.transparent .react-select__clear-indicator {
	color: #737689 !important;
}

.custom-async-loading {
	position: absolute;
	right: 38px;
	top: 6px;
}

.react-select-container.center-placeholder .react-select__placeholder {
	text-align: center;
	width: 100%;
}
