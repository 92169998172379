.floating-bar {
	min-height: 100%;
	height: 100%;
	transition: width 0.7s 0.5s;
	width: 0;
	position: fixed;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(121, 42, 213, 0.95);
	z-index: 99;
	font-family: Avenir-Heavy !important;
}

.floating-bar.blue {
	background-color: rgba(40, 151, 200, 0.95);
}

.floating-bar.open {
	width: 40%;
	transition: width 0.7s;
}

.floating-bar .title-legend {
	color: white !important;
}

.floating-bar > .content {
	opacity: 0;
	padding-left: 20px;
	padding-right: 20px;
	transition: opacity 0.5s;
	flex-direction: column;
}

.floating-bar.open > .content {
	opacity: 1;
	transition: opacity 0.5s 0.5s;
}

@media (max-width: 768px) {
	.floating-bar.open {
		width: 100% !important;
	}
}

.floating-bar.floating-sm.open {
	width: 30%;
}

.floating-bar.floating-xl.open {
	width: 75%;
}

@media(max-width: 1600px) {
	.floating-bar.floating-sm.open {
		width: 38%;
	}
}
