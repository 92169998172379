.form-sidebar {
	display: flex;
	flex-direction: column;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 15px;
}

.form-sidebar input {
    background: transparent;
    border: none;
    color: white;
    width: 100%;
	height: 32px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.27);
}

.form-sidebar label {
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: bold;
}

.form-sidebar input:disabled:not(.disabled) {
	opacity: 1;
}

.content-image {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2em;
    font-size: 20px;
}

.block {
    margin-bottom: 3em;
    flex-direction: column;
    text-align: center;
}

.inline {
    width: 100%;
    display: flex;
	position: relative;
    align-items: center;
    margin-bottom: 0.8em;
}

.sync-social {
    display: flex;
    justify-content: space-between;
}

.avatar-profile {
    width: 102px;
    height: 102px;
    border-radius: 100%;
    border: 3px solid white !important;
}

.profile-page {
    color: white;
}

.float-btn {
    max-height: 27px !important;
    margin-top: 0 !important;
    font-size: 10px !important;
    padding: 4px 11px !important;
}

.info-company {
    text-align: center;
    white-space: normal;
    word-break: break-word;
    font-family: Avenir-Medium;
}

