.navigation {
	display: flex;
  height: 100%;
  background-color: #2897C8;
  position: fixed;
  width: 112px;
	z-index: 10;
	overflow: auto;
	transition: width 0.5s;
}

.nav-menu {
	opacity: 1;
	transition: opacity 0.5s 0.5s;
}

.nav-tenant {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
}

.nav-bottom {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	margin: 10px;
}

.navigation.closed {
	width: 0;
	transition: width 0.5s 0.5s;
}

.navigation.closed .nav-menu {
	opacity: 0;
	transition: opacity 0.5s;
}

.nav-menu {
	flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
	justify-content: flex-start;
}

.nav-header {
	display: flex;
  align-items: center;
	justify-content: center;
  margin: 10px;
}

.nav-header-icon {
	width: 40px;
}

.nav-header-tenant {
	width: 0;
}

.logo-element {
    background: url(//s3-sa-east-1.amazonaws.com/eyemobile-public/design/SaaS+Project/Icons/Sidebar/ic_sidebar_logo_eyemobile.svg) no-repeat center;
    width: 40px;
    height: 40px;
    background-size: contain;
}

.menu-content {
  margin-top: 15px;
	margin-bottom: 15px;
	color: white;
}

.menu-content.has-submenu {
	position: relative;
}

.menu-content.has-submenu .submenu-content {
	background-color: #541C90;
	border-radius: 15px;
	padding: 8px 22px;
	text-align: left;
	position: relative;
	text-transform: uppercase;
	font-size: 14px;
}

.submenu-content > div {
	display: flex;
	flex-direction: column;
}

.submenu-content a {
	font-size: 11px;
	display: block;
	padding: 10px 0;
	width: 100%;
	cursor: pointer;
}

.submenu-content a:hover {
	text-decoration: underline;
}

.submenu-content:before {
	content: '';
	display: block;
	border-right: 12px solid #541C90;
	border-top: 8px solid transparent;
	border-bottom: 8px solid transparent;
	height: 18px;
	position: absolute;
	left: -12px;
	top: 20px;
}

.submenu-content:after {
	content: '';
	height: 5px;
	position: absolute;
	background-color: #541C90;
	display: block;
	top: 26px;
	left: -11px;
	width: 4px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}

.menu-content.has-submenu:hover .menu-content__bridge {
	display: block;
}

.menu-content.has-submenu .menu-content__sub a {
	color: white;
	text-transform: uppercase;
}

.menu-content > a {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	opacity: 0.5;
}

.menu-content.active > a, .menu-content:hover > a:not(.mobile-link) {
  opacity: 1;
}

.ic-user-white {
	width: 24px;
	height: 24px;
}

.nav-label {
  font-size: 11px;
  margin-top: 5px;
  text-transform: uppercase;
}

.active .nav-label {
  color: #fff;
}

a:focus, a:hover {
  color: inherit;
  text-decoration: none;
}

@media(max-width: 768px) {
	.navigation.open {
		width: 100%;
		opacity: 0.95;
	}

	.navigation:not(.open) {
		width: 0;
	}

	.menu-content {
		margin-left: 20px;
		align-self: flex-start;
	}

	.menu-content > a {
		flex-direction: row;
		justify-content: flex-start;
	}

	.menu-content .nav-label {
		margin-left: 15px;
	}

	.nav-menu .nav-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-bottom: 0;
	}
}

.sidebar-tenant {
	width: 38px;
	height: 38px;
	border-radius: 100%;
	margin-right: 15px;
}
