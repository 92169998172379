.form-control,
.rounded-input {
	display: block;
	width: 100%;
	height: 35px;
	padding: 6px 16px;
	font-size: 14px;
	line-height: 1.52857143;
	color: #3a3f51;
	background-color: #ffffff;
	background-image: none;
	border: 1px solid #dde6e9;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.search-input {
	width: 310px;
	max-width: 310px;
	display: flex;
}

.search-input > input {
	border-radius: 17px !important;
	padding: 6px 16px 6px 38px !important;
	height: 38px !important;
	border: 1px solid #BABDC5;
	color: #737689;
	font-size: 14px;
}

.search-input > input::placeholder {
	text-transform: uppercase;
	font-size: 12px;
}

.search-input > .fa.fa-search {
	position: absolute;
	margin-top: 9px;
	margin-left: 10px;
	color: #737689;
	font-size: 18px;
}

.input-icon {
	position: absolute;
	right: 5px;
	top: 5px;
}

.input-ic {
	position: absolute;
	right: -35px;
	top: 50%;
	transform: translate(-50%, -50%);
}

.input-container {
	width: 100%;
	position: relative;
}

.input-container  .react-datepicker-wrapper, .input-container .react-datepicker__input-container{
	width: 100%;
}

.input-icon-custom {
	right: 1px !important;
	top: 2px !important;
}

.input-default,
.input-sm,
.input-md,
.input-xl {
	font-size: 14px;
	width: 100%;
	border: none;
	border-bottom: 1px solid #a9a9a9;
	color: #737689;
	height: 35px;
}

.input-sm {
	width: 80px;
}

.input-md {
	width: 100px;
}

.input-md2 {
	width: 120px;
}

.input-xl {
	width: 300px;
	height: 70px;
	font-size: 45px;
	font-family: Avenir-Roman;
}

.input-w-158 {
	width: 158px;
}

@media(max-width: 1366px) {
	.input-xl {
		width: 260px;
	}
}

.input-container-sm,
.input-container-md {
	width: auto;
}

.input-container-sm > .ic-error {
	right: 0;
}

.input-button {
	position: absolute;
	right: 0;
}

.input-default[type=number]::-webkit-outer-spin-button,
.input-default[type=number]::-webkit-inner-spin-button,
.input-sm::-webkit-outer-spin-button,
.input-sm::-webkit-inner-spin-button,
.input-md::-webkit-outer-spin-button,
.input-md::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input:disabled {
	background: transparent;
	opacity: 0.5;
}

.rounded-input-wrapper {
	width: 310px;
	display: flex;
}

.rounded-input {
	border-radius: 17px !important;
	padding: 6px 16px 6px 16px !important;
	height: 35px !important;
	border: 1px solid #BABDC5;
	color: #737689;
	font-size: 14px;
}

.input-button-container div.ic-success.input-icon {
	right: 92px !important;
}

@media(max-width: 768px) {
	.search-input {
		width: 100%;
	}
}

input.uppercase-placeholder::-webkit-input-placeholder {
	font-size: 12px;
	text-transform: uppercase;
}

input.white {
	background: transparent;
	color: #fff;
	width: 100%;
	height: 32px;
	border: none;
	border-bottom: 1px solid hsla(0,0%,100%,.27);
}

input.white::-webkit-input-placeholder {
	color: #fff;
}
