.flex {
  display: flex;
}

.flex-80 {
  display: flex;
  width: 80%;
}

.flex-align-center {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.flex-align-center-row {
  display: flex;
  align-items: center;
}

.filter-grouping {
  display: flex;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.flex-block {
  display: flex;
  flex: 1;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.align-i-start {
  align-items: flex-start;
}

.align-i-end {
  align-items: flex-end;
}

.align-i-center {
  align-items: center;
}

.align-i-base {
  align-items: baseline;
}

.align-i-stretch {
  align-items: stretch;
}

.align-c-start {
  align-content: start;
}

.align-c-end {
  align-content: end;
}

.align-c-center {
  align-content: center;
}

.align-c-between {
  align-content: space-between;
}

.align-c-around {
  align-content: space-around;
}

.align-c-stretch {
  align-content: stretch;
}

.align-s-auto {
  align-self: auto;
}

.align-s-start {
  align-self: flex-start;
}

.align-s-end {
  align-self: flex-end;
}

.align-s-center {
  align-self: center;
}

.align-s-baseline {
  align-self: baseline;
}

.align-s-stretch {
  align-self: stretch;
}

.align-i-flex-end {
  align-items: flex-end;
}

.flex-row {
  flex-direction: row;
}

.flex-row-rev {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-rev {
  flex-direction: column-reverse;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-rev {
  flex-wrap: wrap-reverse;
}

.no-grow {
  flex-grow: 0;
}

.grow {
  flex-grow: 1;
}

.flex-align-center {
  align-items: center;
  flex-direction: column;
  display: flex;
}

.flex-align-center-row {
  align-items: center;
  display: flex;
}

.flex-align-center-justify {
  justify-content: center;
  align-items: center;
  display: flex;
}

.flex-align-center-justify-start {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.adjust-center {
  justify-content: center;
  min-height: initial !important;
}

.no-shrink {
  flex-shrink: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

@media(max-width: 768px) {
  .flex-column-xs {
    flex-direction: column !important;
  }

  .justify-start-xs {
    justify-content: flex-start !important;
  }

  .align-i-start-xs {
    align-items: flex-start !important;
  }

  .align-i-center-xs {
    align-items: center !important;
  }

  .flex-wrap-xs {
    flex-wrap: wrap;
  }
}
