.navbar {
	display: flex;
	min-height: 50px;
	padding: 0;
	flex-wrap: inherit;
	justify-content: space-between;
	width: calc(100% - 25px);
}

@media (max-width: 768px) {
	.navbar {
		flex-wrap: wrap;
	}

	.eye-breadcrumb {
		width: auto !important;
		justify-content: flex-start !important;
		margin-left: 0 !important;
	}
}

@media (width: 768px) {
	.eye-breadcrumb {
		margin-left: 1.6em !important;
	}
}

.navbar-header {
	margin-left: 1em;
}

@media (min-width: 768px) {
	.navbar-header {
		display: none;
	}
}

.eye-breadcrumb {
	display: flex;
	color: #737689;
	justify-content: flex-start;
	align-items: center;
	margin-left: 40px;
	font-family: Avenir-Heavy;
}

.eye-breadcrumb span {
	font-size: 13px;
	text-transform: uppercase;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.separator {
	border: 1px solid #737689;
	margin-left: 1em;
	margin-right: 1em;
	height: 13px;
	background-color: #737689;
}

.content-header {
	border-bottom: 1px solid #EAECEF;
	background-color: #f5f7fa;
	position: fixed;
	width: 100%;
	z-index: 9;
}

.avatar, .place-holder-event {
	max-width: 30px;
}

.avatar {
	margin-right: 1em;
	border: 2px solid rgb(115, 118, 137);
	border-radius: 100%;
	cursor: pointer;
}

.place-holder-event {
	width: 30px;
	height: 30px;
	vertical-align: inherit;
	border: 2px solid rgb(115, 118, 137);
	border-radius: 100%;
	margin-right: 1em;
}

.popover-user > .popover-inner {
	align-items: flex-start !important;
}

.menu-list {
	margin-right: 30px;
}

.menu-list .menu-item:last-child {
	margin-bottom: 0;
}

.menu-item {
	font-size: 14px;
	display: flex;
	text-transform: uppercase;
	align-items: center;
	cursor: pointer;
	margin-bottom: 12px;
}

.menu-item:hover {
	text-decoration: underline;
}

.menu-item i {
	display: block;
	margin-right: 5px;
}

.menu-item i.ic-user-white {
	width: 16px !important;
	height: 18px !important;
}

.menu-item i.ic-company {
	background-size: 16px;
}

.menu-item span {
	line-height: 1.1;
}
