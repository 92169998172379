.vertical-scroll {
    overflow-y: auto !important;
	overflow-x: hidden !important;
}

.react-select__menu-list::-webkit-scrollbar,
.vertical-scroll::-webkit-scrollbar,
.custom-vscroll::-webkit-scrollbar {
	width: 5px;
}

.custom-hscroll::-webkit-scrollbar {
	height: 5px;
}

/* Track */
.react-select__menu-list::-webkit-scrollbar-track,
.vertical-scroll::-webkit-scrollbar-track,
.custom-vscroll::-webkit-scrollbar-track,
.custom-hscroll::-webkit-scrollbar-track {
	background: #f5f7fa;
}

/* Handle */
.react-select__menu-list::-webkit-scrollbar-thumb,
.vertical-scroll::-webkit-scrollbar-thumb,
.custom-vscroll::-webkit-scrollbar-thumb,
.custom-hscroll::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	background: #b3b3b3;
}

/* Handle on hover */
.react-select__menu-list::-webkit-scrollbar-thumb:hover,
.vertical-scroll::-webkit-scrollbar-thumb:hover,
.custom-vscroll::-webkit-scrollbar-thumb:hover,
.custom-hscroll::-webkit-scrollbar-thumb:hover {
    background: #828282;
}

.horizontal-scroll.vertical-scroll {
	overflow: auto !important;
}

.horizontal-scroll {
	overflow-y: hidden !important;
	overflow-x: auto !important;
}

.horizontal-scroll {
    padding-bottom: 12px;
    margin-right: 0;
}

.horizontal-scroll::-webkit-scrollbar {
    height: 6px;              /* height of horizontal scrollbar */
    width: 6px;               /* width of vertical scrollbar */
}

.horizontal-scroll::-webkit-scrollbar-track {
    background: #f5f7fa;
}

.horizontal-scroll::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
    background: #b3b3b3;
}

.horizontal-scroll::-webkit-scrollbar-thumb:hover {
    background: #828282;
}

@media(max-width: 768px) {
	.horizontal-scroll-xs {
		overflow-y: hidden !important;
		overflow-x: auto !important;
	}

	.horizontal-scroll-xs {
		padding-bottom: 12px;
		margin-right: 0;
	}

	.horizontal-scroll-xs::-webkit-scrollbar {
		height: 6px;              /* height of horizontal scrollbar */
		width: 6px;               /* width of vertical scrollbar */
	}

	.horizontal-scroll-xs::-webkit-scrollbar-track {
		background: #f5f7fa;
	}

	.horizontal-scroll-xs::-webkit-scrollbar-thumb {
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		border-radius: 10px;
		background: #b3b3b3;
	}

	.horizontal-scroll-xs::-webkit-scrollbar-thumb:hover {
		background: #828282;
	}
}
